import { isValidElement, useContext } from 'react';

import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';

export const BookViewTracker = modularComponent(
  () =>
    ({ children }) => {
      const { bookId, trackingOptions } = useContext(BookDataContext);
      const { trackingData: sectionTrackingData } = useContext(BookDefinitionContext);

      if (!isValidElement(children) || !sectionTrackingData || trackingOptions?.disableTrackEvent) {
        return <>{children}</>;
      }

      return (
        <TrackViewEvent
          screenName={sectionTrackingData.screenName}
          target="book"
          params={{
            book_id: bookId,
            ...sectionTrackingData.params,
            ...trackingOptions?.extraParams,
          }}>
          {children}
        </TrackViewEvent>
      );
    },
  {},
);
