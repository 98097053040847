import { css } from '@emotion/react';

export const purchaseStatusWrapperStyle = css`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`;

export const purchaseStatusTextStyle = css`
  display: block;
  background: rgba(0, 0, 0, 0.8);
  padding: 7px;
  box-sizing: content-box;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
`;
