import { css } from '@emotion/react';

export const badgeBackgroundStyle = css`
  display: block;
  position: absolute;
  left: -7px;
  top: -7px;
  line-height: 0;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
`;

export const badgeTextStyle = css`
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1em;
  margin-top: -0.5em;
  color: white;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: -0.05em;
  text-align: center;
`;
