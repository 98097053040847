import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';

import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import * as styles from './BookBadge.styles';

const BadgeWrapper = styled.div<{ background: string }>`
  ${styles.badgeBackgroundStyle};
  background: ${({ background }) => background};
`;

export interface BookBadgeProps {
  ariaLabel?: string;
  background: string;
  className?: string;
  children?: ReactNode;
}

export const BookBadge = modularComponent(
  ({ size }) => {
    const badgeWrapperSizeStyle = modularResponsiveStyle(
      sizeValue => css`
        width: ${sizeValue}px;
        height: ${sizeValue}px;
        border-radius: ${sizeValue}px;
      `,
      size,
    );

    return ({ className, children, ariaLabel, background }: BookBadgeProps) => (
      <BadgeWrapper className={className} css={badgeWrapperSizeStyle} background={background} aria-label={ariaLabel}>
        {children}
      </BadgeWrapper>
    );
  },
  { size: [{ value: 34 }] as ModularResponsiveOption<number> },
);

export const BookBadgeText = modularComponent(() => ({ children, ...props }) => (
  <span css={styles.badgeTextStyle} {...props}>
    {children}
  </span>
));
