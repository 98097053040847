import { useContext } from 'react';

import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { RenewalBookConstantsDefault as constants } from '../constants';
import * as styles from './RenewalBookTitle.styles';

export const RenewalBookTitle = modularComponent(
  ({ textSize, lineHeight, lineCount }) => {
    const titleTextStyle = [
      styles.titleTextStyle,
      modularResponsiveStyle(styles.titleTextSizeStyle, textSize),
      modularResponsiveStyle(styles.titleTextLineHeightStyle(lineCount), lineHeight),
    ];

    return ({ children, className, index = 0 }) => {
      const { BookLink } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);

      const { title } = bookData;
      if (!title || bookData.metadata?.title?.showTitle === false) {
        return <></>;
      }

      return (
        <div>
          <BookLink css={titleTextStyle} className={className} index={index}>
            {title}
            {children}
          </BookLink>
        </div>
      );
    };
  },
  {
    textSize: [{ value: constants.BookTitle.textSize }] as ModularResponsiveOption<number>,
    lineHeight: [{ value: constants.BookTitle.lineHeight }] as ModularResponsiveOption<number>,
    lineCount: 2,
  },
);
