import { useContext } from 'react';

import { formatNumber } from '@/utils/formatNumber';
import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';
import * as styles from './BookSeries.styles';

export const BookSeriesCompletion = modularComponent(() => ({ className }) => {
  const bookData = useContext(BookDataContext);

  if (!bookData.metadata?.extra?.series?.completion) {
    return <></>;
  }

  return (
    <span className={className} css={styles.completionStyle}>
      완결
    </span>
  );
});

export const BookSeries = modularComponent(() => ({ className }) => {
  const { BookDefaultMetadataItem, BookSeriesCompletion: BookContextSeriesCompletion } =
    useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const series = bookData.metadata?.extra?.series;
  if (!series) {
    return <></>;
  }

  if (typeof series.total !== 'number' || !Number.isInteger(series.total) || series.total < 1) {
    return <></>;
  }

  const unit = series.unit ?? '화';

  return (
    <BookDefaultMetadataItem className={className}>
      <span css={styles.textStyle}>
        총 <span css={styles.numberStyle}>{formatNumber(series.total)}</span>
        {unit}
      </span>
      <BookContextSeriesCompletion />
    </BookDefaultMetadataItem>
  );
});
