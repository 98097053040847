import { css } from '@emotion/react';

export const landscapeBookStyle = css`
  display: flex;
`;

export const landscapeCoverStyle = css`
  padding: 20px 15px;
  padding-right: 0;
  flex: 0 0 auto;
`;

export const landscapeMetadataStyle = css`
  padding: 20px 15px;
  flex: 1;
`;
