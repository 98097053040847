import { css, SerializedStyles } from '@emotion/react';
import colors from '@ridi/colors';

export const thumbnailWrapperStyle = (size: number): SerializedStyles => css`
  width: ${size}px;
`;

export const thumbnailStyle = css`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const thumbnailUnloadedStyle = css`
  padding-bottom: 142%;
  height: 0;
  background-image: linear-gradient(147deg, ${colors.slateGray10}, #edeff2 55%, ${colors.slateGray10});
`;

export const thumbnailImageStyle = (size: number): SerializedStyles => css`
  display: block;
  width: 100%;
  max-height: ${Math.floor(size * 1.618 - 10)}px;
`;

export const thumbnailBorderStyle = css`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid black;
  opacity: 0.1;
`;

export const thumbnailGradientStyle = css`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 0) 95%,
    rgba(0, 0, 0, 0.2) 100%
  );
`;
