import { css, SerializedStyles } from '@emotion/react';
import colors from '@ridi/colors';

import { lineClamp } from '@/components/styles';

export const titleStyle = css`
  letter-spacing: -0.03em;
`;

export const titleTextSizeStyle = (size: number): SerializedStyles => css`
  font-size: ${size}px;
`;

export const titleTextStyle = css`
  font-weight: bold;
  color: ${colors.gray80};
  ${lineClamp(2, '1.4em')};
`;

export const titleLightTextStyle = css`
  font-weight: normal;
  color: ${colors.gray60};
`;
