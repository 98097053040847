import { css } from '@emotion/react';

import { BookCoverRenderer } from '@/components/common/Book/BookCoverRenderer/BookCoverRenderer';
import { BookNothing } from '@/components/common/Book/BookNothing';

export const RenewalBookCoverDimmer = BookNothing;

// RenewalBook 의 19금 뱃지 노출정책이 변경됨에 따라 19금 표지를 렌더 하지 않음
export const RenewalBookAdultIndicator = BookNothing;
export const RenewalBookCoverRenderer = BookCoverRenderer.addStyle(css`
  // crop 모드 이미지 시에 absolute로 변경되는 이에 따라서 Safari에서 상위 wrapper인 CoverRender의 스타일을 무시하게됨
  // reference: https://www.sungikchoi.com/blog/safari-overflow-border-radius/
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  border-radius: 4px;
  overflow: hidden;
`);
