import { useContext } from 'react';

import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext } from '../../BookDataContext';
import { BookDefinitionContext } from '../../BookDefinitionContext';
import * as styles from './BookNewBadge.styles';

export const BookNewBadge = modularComponent(
  ({ textSize }) => {
    const newTextStyle = modularResponsiveStyle(styles.newTextStyle, textSize);

    return ({ className }): ReactJSX.Element => {
      const { BookBadge, BookBadgeText } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);

      const newRelease = bookData.cover?.badges?.newRelease;
      if (!newRelease) {
        return <></>;
      }

      return (
        <BookBadge background="#3ea590" className={className}>
          <BookBadgeText css={newTextStyle}>NEW</BookBadgeText>
        </BookBadge>
      );
    };
  },
  { textSize: [{ value: 12 }] as ModularResponsiveOption<number> },
);
