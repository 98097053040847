import { useContext } from 'react';

import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../../BookDataContext';
import { BookDefinitionContext } from '../../BookDefinitionContext';
import * as styles from './BookTitleBadgeRenderer.styles';

export const BookTitleBadgeRenderer = modularComponent(() => ({ className }) => {
  const { BookDeductionUnavailableTitleBadge, BookSomedealTitleBadge } = useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  if (!bookData.metadata?.extra?.titleBadge) {
    return <></>;
  }

  return (
    <div css={styles.titleBadgeRendererStyle} className={className}>
      <BookDeductionUnavailableTitleBadge />
      <BookSomedealTitleBadge />
    </div>
  );
});
