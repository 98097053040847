import { useTheme } from '@emotion/react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { useBookLazyLoadObserverRef } from '@/components/common/Book/BookLazyLoadObserver';
import { BookNothing } from '@/components/common/Book/BookNothing';
import { useThumbnailSrc } from '@/components/common/Book/hooks/useThumbnailSrc';
import { getImageSizes, TRANSPARENT_COVER } from '@/components/common/Book/utils/thumbnail';
import { useLoggedUser } from '@/hooks/useLoggedUser';
import {
  getDefaultResponsiveValue,
  modularComponent,
  ModularResponsiveOption,
  modularResponsiveStyle,
} from '@/utils/modularComponent';

import * as styles from './RenewalBookThumbnail.styles';

export type RenewalBookThumbnailType = { width: number; height: number };
export type RenewalBookThumbnailCropMode = 'auto' | 'crop' | 'none';

export const THUMBNAIL_SHORT_THRESHOLD = 1.343373494; /* (Height = 223) / (Width = 166) */
export const THUMBNAIL_LONG_THRESHOLD = 1.5324675325; /* (Height = 236) / (Width = 154) */

export const RenewalBookThumbnail = modularComponent(
  ({
    type,
    cropMode,
    lazyload,
  }: {
    type: ModularResponsiveOption<RenewalBookThumbnailType>;
    cropMode: RenewalBookThumbnailCropMode;
    lazyload: boolean;
  }) => {
    const imageSizes = getImageSizes(type.map(option => ({ ...option, value: option.value.width })));
    const defaultSize = getDefaultResponsiveValue(type);

    return ({ className, children }) => {
      const bookData = useContext(BookDataContext);
      const [imageResolution, setImageResolution] = useState<{ width: number; height: number } | null>(null);
      const [imageCrop, setImageCrop] = useState(cropMode !== 'none');
      const [imageFill, setImageFill] = useState(cropMode !== 'none');
      const [isImageLoaded, setIsImageLoaded] = useState(false);
      const [isImageLazyLoaded, setIsImageLazyLoaded] = useState(!lazyload);

      const { src: thumbnailSrc, srcSet: thumbnailSrcSet } = useThumbnailSrc(defaultSize.width, isImageLazyLoaded);
      const isUserVerifiedAdult = useLoggedUser()?.isVerifiedAdult;
      const isAdultOnly = bookData.cover?.isAdultOnly;
      const showAdultCover = isAdultOnly && !isUserVerifiedAdult;
      const src = showAdultCover ? undefined : thumbnailSrc;
      const srcSet = showAdultCover ? undefined : thumbnailSrcSet;

      const isFallbackCover = !src;
      const shouldShowImage = isImageLoaded && !isFallbackCover;

      const viewRef = useBookLazyLoadObserverRef<HTMLDivElement>(() => setIsImageLazyLoaded(true));
      const ref = lazyload ? viewRef : null;

      const imageRef = useRef<HTMLImageElement>(null);
      const checkImageStatus = () => {
        if (!imageRef.current) {
          return;
        }

        setIsImageLoaded(imageRef.current.complete);

        const ratio = imageRef.current.naturalHeight / imageRef.current.naturalWidth;
        setImageResolution({ width: imageRef.current.naturalWidth, height: imageRef.current.naturalHeight });

        if (cropMode !== 'auto') {
          return;
        }

        if (THUMBNAIL_SHORT_THRESHOLD < ratio && ratio < THUMBNAIL_LONG_THRESHOLD) {
          // Basic Type
          setImageCrop(true);
          setImageFill(true);
        } else if (ratio >= THUMBNAIL_LONG_THRESHOLD) {
          // Long Type
          setImageCrop(false);
          setImageFill(true);
        } else if (ratio <= THUMBNAIL_SHORT_THRESHOLD) {
          // Short Type
          setImageCrop(false);
          setImageFill(false);
        }
      };

      useEffect(() => {
        if (isFallbackCover) {
          return;
        }

        checkImageStatus();
      }, [isFallbackCover, src]);

      const theme = useTheme();
      const builtStyles = useMemo(() => {
        const thumbnailWrapperStyle = [styles.thumbnailWrapperStyle(theme)];
        const thumbnailStyle = [styles.thumbnailStyle(theme)];
        const thumbnailImageStyle = [styles.thumbnailImageStyle(shouldShowImage)];

        const thumbnailSize = imageResolution || defaultSize;
        const thumbnailRatio = thumbnailSize.height / thumbnailSize.width;

        // Crop일 때 -> 짧은 쪽을 맞춤
        // Crop이 아닐 때 -> 긴 쪽을 맞춤
        const thumbnailImageFitStyle = modularResponsiveStyle(value => {
          const isThumbnailWider = thumbnailRatio <= value.height / value.width;
          return imageCrop !== isThumbnailWider
            ? styles.thumbnailImageWidthFitStyle
            : styles.thumbnailImageHeightFitStyle;
        }, type);
        thumbnailImageStyle.push(thumbnailImageFitStyle);

        const thumbnailFitStyle = modularResponsiveStyle(value => {
          const isThumbnailWider = thumbnailRatio <= value.height / value.width;
          return imageCrop !== isThumbnailWider ? styles.thumbnailWidthFitStyle : styles.thumbnailHeightFitStyle;
        }, type);
        thumbnailStyle.push(thumbnailFitStyle);

        const thumbnailWrapperFitStyle = thumbnailImageFitStyle;
        thumbnailWrapperStyle.push(thumbnailWrapperFitStyle);

        if (imageFill) {
          thumbnailWrapperStyle.push(
            styles.thumbnailWrapperFillStyle(theme),
            modularResponsiveStyle(styles.thumbnailWrapperFillSizeStyle, type),
          );
          thumbnailStyle.push(styles.thumbnailFillStyle);
        }

        if (imageCrop) {
          thumbnailStyle.push(styles.thumbnailCropStyle);
          thumbnailImageStyle.push(styles.thumbnailImageCropStyle);
        }

        return { thumbnailWrapperStyle, thumbnailStyle, thumbnailImageStyle };
      }, [theme, imageCrop, imageFill, imageResolution, shouldShowImage]);

      return (
        <div css={builtStyles.thumbnailWrapperStyle} className={className} ref={ref}>
          <div css={builtStyles.thumbnailStyle}>
            <img
              css={builtStyles.thumbnailImageStyle}
              src={src ?? TRANSPARENT_COVER.src}
              srcSet={srcSet}
              sizes={imageSizes}
              alt={bookData.title ?? ''}
              ref={imageRef}
              onLoad={checkImageStatus}
            />
          </div>

          {children}
        </div>
      );
    };
  },
  {
    type: [{ value: { width: 120, height: 174 } }],
    cropMode: 'auto',
    lazyload: true,
  },
);

export const RenewalBookThumbnailBorder = BookNothing;
export const RenewalBookThumbnailGradient = BookNothing;
