import { css } from '@emotion/react';

export const titleBadgeWrapperStyle = css`
  display: inline-block;
  border-radius: 3px;
  color: black;
  line-height: 12px;
  text-align: center;
  padding: 4px 0;
`;
