import { useContext } from 'react';

import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import * as styles from './BookFreebook.styles';

export const BookFreebook = modularComponent(() => ({ className }): ReactJSX.Element => {
  const bookData = useContext(BookDataContext);
  const freeBookCount = bookData.cover?.freeBook?.count;
  const freeBookUnit = bookData.cover?.freeBook?.unit ?? '권';

  if (typeof freeBookCount !== 'number' || !Number.isFinite(freeBookCount) || freeBookCount <= 0) {
    return <></>;
  }

  return (
    <div className={className} css={styles.freeBookWrapperStyle}>
      <span css={styles.freeBookTextStyle}>{`${freeBookCount}${freeBookUnit}`} 무료</span>
    </div>
  );
});
