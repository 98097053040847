import { useContext } from 'react';

import { IconBadgeAdult } from '@/assets/svgs/system';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { RenewalBookConstantsDefault as constants } from '../constants';
import * as styles from './RenewalBookAdultBadge.styles';

export const RenewalBookAdultBadge = modularComponent(
  ({ iconSize, position }) => {
    const iconSizeStyle = modularResponsiveStyle(styles.iconSizeStyle, iconSize);
    const positionStyle = modularResponsiveStyle(styles.positionStyle, position);

    return ({ className }) => {
      const bookData = useContext(BookDataContext);

      if (!bookData.cover?.isAdultOnly) {
        return <></>;
      }

      return (
        <IconBadgeAdult
          aria-label="19세 미만 구독불가"
          css={[styles.iconStyle, iconSizeStyle, positionStyle]}
          className={className}
        />
      );
    };
  },
  {
    iconSize: [{ value: constants.BookAdultBadge.iconSize }] as ModularResponsiveOption<number>,
    position: [{ value: constants.BookAdultBadge.position }] as ModularResponsiveOption<number>,
  },
);
