import { useContext } from 'react';

import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';
import { joinComponents } from '../utils/joinComponents';

export const BookTranslators = modularComponent(
  ({ clipCount, clipBreakPoint }) =>
    ({ className }) => {
      const { BookAuthorItem, BookDefaultMetadataItem } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);

      const authors = bookData.metadata?.authors;
      const showTranslator = bookData.metadata?.extra?.translators?.showTranslators;

      if (!authors || !Array.isArray(authors) || !showTranslator) {
        return <></>;
      }

      const translators = authors.filter(author => author?.role === 'translator') as {
        id?: number;
        role: 'translator';
      }[];

      if (translators.length === 0) {
        return <></>;
      }

      const translatorNames = translators.map((translator, index) => (
        <BookAuthorItem author={translator} key={translator.id || `translator-${index}`} />
      ));

      return (
        <BookDefaultMetadataItem className={className}>
          {clipCount !== null && translatorNames.length > clipBreakPoint ? (
            <>
              {joinComponents(translatorNames.slice(0, clipCount), ', ')}
              {` 외 ${translatorNames.length - clipCount}명`}
            </>
          ) : (
            joinComponents(translatorNames.slice(0, clipBreakPoint), ', ')
          )}
          {' 역'}
        </BookDefaultMetadataItem>
      );
    },
  { clipCount: 1 as number | null, clipBreakPoint: 2 },
);
