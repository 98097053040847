import { createElement, ReactNode, useContext } from 'react';

import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { EventParamsType, EventParamsValueType } from '@/utils/eventClient';
import { modularComponent } from '@/utils/modularComponent';
import { addRDTTrackingURIQuery } from '@/utils/query';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';

interface BookLinkProps {
  className?: string;
  children?: ReactNode;
  component?: keyof ReactJSX.IntrinsicElements;
  index?: number;
}

interface TrackingBookLinkParams extends EventParamsType {
  book_id: EventParamsValueType;
}

export const BookLink = modularComponent<Record<string, Any>, BookLinkProps>(
  () =>
    ({ className, children, component = 'span', index = 0 }): ReactJSX.Element => {
      const sectionData = useContext(BookDefinitionContext);
      const bookData = useContext(BookDataContext);

      const bookId = bookData?.bookId;
      const linkData = bookData?.link;
      if (linkData === null || (linkData === undefined && bookId === undefined)) {
        return createElement(component, { className }, children);
      }

      let link = linkData !== undefined ? linkData : `/books/${bookId as string}`;

      if (sectionData.trackingData?.rdtTrackingURIParams) {
        const sectionArgOverride = bookData.trackingOptions?.rdtTrackingURIParams?.sectionArg;

        link = addRDTTrackingURIQuery(link, {
          ...sectionData.trackingData?.rdtTrackingURIParams,
          ...(sectionArgOverride ? { sectionArg: sectionArgOverride } : null),
          sectionItemIdx: index,
        });
      }

      const linkContent = (
        <a href={link} className={className}>
          {children}
        </a>
      );

      if (!sectionData.trackingData || bookData.trackingOptions?.disableTrackEvent) {
        return linkContent;
      }

      const computedParams: TrackingBookLinkParams = {
        book_id: bookData.bookId,
        ...sectionData.trackingData.params,
        ...bookData.trackingOptions?.extraParams,
      };

      return (
        <TrackClickEvent screenName={sectionData.trackingData.screenName} target="book" params={computedParams}>
          {linkContent}
        </TrackClickEvent>
      );
    },
);
