import { css } from '@emotion/react';

export const freeBookWrapperStyle = css`
  position: absolute;
  height: 26px;
  bottom: 0;
  right: 0;
  padding: 7px 6px 5px 6px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 0;
  border-bottom: 0;
  border-radius: 3px 0 0 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0.9;
  font-size: 12px;
  line-height: 12px;
`;

export const freeBookTextStyle = css`
  color: white;
  font-weight: bold;
`;
