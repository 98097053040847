import { forwardRef, useContext } from 'react';

import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { modularComponent, ModularComponentProps } from '@/utils/modularComponent';

import * as styles from './LandscapeBookRenderer.styles';

export const LandscapeBookRenderer = modularComponent(() =>
  forwardRef<HTMLDivElement, ModularComponentProps>(({ className, children, index = 0 }, ref) => {
    const { BookCoverRenderer, BookMetadataRenderer, BookViewTracker } = useContext(BookDefinitionContext).components;

    return (
      <BookViewTracker>
        <div css={styles.landscapeBookStyle} className={className} ref={ref}>
          <div css={styles.landscapeCoverStyle}>
            <BookCoverRenderer index={index} />
          </div>

          <div css={styles.landscapeMetadataStyle}>
            <BookMetadataRenderer index={index} />
            {children}
          </div>
        </div>
      </BookViewTracker>
    );
  }),
);
