import { css, SerializedStyles } from '@emotion/react';

export const discountNumberStyle = (numberSize: number): SerializedStyles => css`
  font-size: ${numberSize}px;
  padding-right: 1px;
`;

export const discountPercentStyle = (percentSize: number): SerializedStyles => css`
  font-size: ${percentSize}px;
`;
