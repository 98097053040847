import { css, SerializedStyles } from '@emotion/react';

import { RIDITheme } from '@/components/styles/themes';
import { rgba } from '@/utils/rgba';

import type { RenewalBookThumbnailType } from '..';

export const thumbnailWrapperStyle = (theme: RIDITheme): SerializedStyles => css`
  position: relative;

  &::after {
    content: '';
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border: 1px solid ${rgba(theme.colors.fillPrimary, 0.06)};
    border-radius: 4px;
  }
`;

export const thumbnailStyle = (theme: RIDITheme): SerializedStyles => css`
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: ${theme.colors.dimThumb};
  }
`;

export const thumbnailImageStyle = (shouldShowImage: boolean): SerializedStyles => css`
  display: block;
  opacity: ${+shouldShowImage};

  @supports (object-fit: contain) {
    object-fit: contain;
  }
`;

// IE만을 위한 스타일, `naturalWidth` 는 아쉽게도 density-corrected 된 CSS Pixel이기 때문에
// srcset이 들어간 시점에서 제대로 된 width를 얻을 수 없음.
// 이 상황에서 선택할 수 있는 방법은 IE를 버리고 object-fit을 사용하거나, offscreen image로 정확한 width를 얻거나인데
// 일단은 object-fit + IE 용 조금 부정확한 스타일을 넣는 것으로 결정함
export const thumbnailImageWidthFitStyle = css`
  width: 100%;
  height: auto;
`;

export const thumbnailWidthFitStyle = css`
  width: 100%;
`;

export const thumbnailImageHeightFitStyle = css`
  width: auto;
  height: 100%;
`;

export const thumbnailHeightFitStyle = css`
  height: 100%;
`;

// Crop: 넘치는 이미지를 `position: absolute` 로 자르기
export const thumbnailCropStyle = css`
  width: 100%;
  height: 100%;
`;

export const thumbnailImageCropStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @supports (object-fit: cover) {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

// Fill: 회색 배경을 추가하고 썸네일을 왼쪽으로 정렬
export const thumbnailWrapperFillStyle = (theme: RIDITheme): SerializedStyles => css`
  &::before {
    content: '';
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: ${theme.colors.grey100};
  }
`;

export const thumbnailWrapperFillSizeStyle = (type: RenewalBookThumbnailType): SerializedStyles => css`
  padding-bottom: ${(type.height / type.width) * 100}%;
`;

export const thumbnailFillStyle = css`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
`;
