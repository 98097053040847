import { memo, ReactNode, useMemo } from 'react';

import { BookComponents, BookDefinitionContext, BookTrackingData } from './BookDefinitionContext';
import * as defaultComponents from './components';
import { BookPreset, BookPresetComponents } from './presets';

export interface BookDefinitionProps {
  children: ReactNode;
  components?: Partial<BookComponents>;
  presets?: BookPreset[];
  trackingData?: BookTrackingData;
}

/**
 * 책 렌더러 컴포넌트들이 사용할 내부 컴포넌트들을 제공해주는 컴포넌트
 * @param {BookPreset[]} [presets] 그릴 책 형태에 따른 기본 컴포넌트 프리셋
 * @param {Partial<BookComponents>} [components] 프리셋 위에 추가로 덮어씌울 컴포넌트
 *
 * @example
 * const MyBookPresets = [ BookDefaultPreset('portrait', 100) ];
 * const MyBookComponents = { BookDiscountBadge: () => <span>할인</span> };
 * const MyBookDefinition = ({ children } = { children: ReactNode }) => (
 *   <BookDefinition
 *     presets={MyBookPresets}
 *     components={MyBookComponents}
 *   >
 *     { children }
 *   </BookDefinition>
 * );
 *
 * <MyBookDefinition>
 *   { items.map(item => <MyBook data={item} /> }
 * </MyBookDefinition>
 */
export const BookDefinition = memo(
  ({ children, components, presets, trackingData }: BookDefinitionProps): ReactJSX.Element => {
    const presetComponents = useMemo(
      () =>
        (presets ?? []).reduce<BookPresetComponents>(
          (previousComponents, preset) => preset(previousComponents),
          defaultComponents,
        ),
      [presets],
    );

    const composedComponents = useMemo(
      () => ({ ...presetComponents, ...components }) as BookComponents,
      [presetComponents, components],
    );

    const definition = useMemo(
      () => ({ components: composedComponents, trackingData }),
      [composedComponents, trackingData],
    );

    return <BookDefinitionContext.Provider value={definition}>{children}</BookDefinitionContext.Provider>;
  },
);
