import { css, SerializedStyles } from '@emotion/react';

import { between, BreakPoint, greaterThan, orBelow } from '@/components/styles/media';

export type ModularResponsiveOption<T> = { orBelow?: BreakPoint; greaterThan?: BreakPoint; value: T }[];
export const modularResponsiveStyle = <T>(
  style: (option: T) => SerializedStyles,
  options: ModularResponsiveOption<T>,
): SerializedStyles =>
  options.length
    ? options
        .map(option => {
          if (option.orBelow && option.greaterThan) {
            return between(option.greaterThan, option.orBelow, style(option.value));
          }

          if (option.orBelow) {
            return orBelow(option.orBelow, style(option.value));
          }

          if (option.greaterThan) {
            return greaterThan(option.greaterThan, style(option.value));
          }

          return style(option.value);
        })
        .reduce(
          (compiledStyle, currentStyle) => css`
            ${compiledStyle};
            ${currentStyle};
          `,
        )
    : css();

export const getDefaultResponsiveValue = <T>(options: ModularResponsiveOption<T>): T => {
  const defaultOption = options.find(option => !option.orBelow && !option.greaterThan);
  return defaultOption?.value ?? options[0].value;
};
