import { css, SerializedStyles } from '@emotion/react';
import colors from '@ridi/colors';

export const priceWrapperStyle = css`
  margin-top: 3px;
`;

export const priceItemWrapperSizeStyle = (textSize: number): SerializedStyles => css`
  font-size: ${textSize}px;
`;

export const priceItemWrapperStyle = css`
  padding: 0.3em 0;
  text-align: left;
  color: ${colors.gray60};
  font-weight: normal;
`;

export const priceInfoStyle = css`
  color: ${colors.dodgerBlue50};
  font-weight: bold;
`;

export const discountInfoStyle = css`
  color: ${colors.red40};
  font-weight: bold;
  margin-left: 4px;
`;

export const discountIconStyle = css`
  width: 0.6em;
  height: 0.8em;
`;

export const originalPriceStyle = css`
  color: ${colors.gray60};
`;

export const additionalInfoStyle = css`
  padding-left: 5px;
`;
