import { BookNothing } from './BookNothing';

export * from './BookCoverRenderer';
export * from './BookFastRenderer';
export * from './BookLink';
export * from './BookMetadataRenderer';
export * from './BookRenderer';
export * from './BookSkeleton';
export { BookNothing };

export const BookAdultBadge = BookNothing;
export const BookFreebookBadge = BookNothing;
export const BookImageBadge = BookNothing;
export const BookSelectBadge = BookNothing;
export const BookTouchArea = BookNothing;
export const BookOriginal = BookNothing;
