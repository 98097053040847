import { useContext } from 'react';

import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';

export const BookGenre = modularComponent(() => ({ className }) => {
  const { BookDefaultMetadataItem } = useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const genre = bookData.metadata?.extra?.genre;
  if (!genre) {
    return <></>;
  }

  return <BookDefaultMetadataItem className={className}>{genre}</BookDefaultMetadataItem>;
});
