import { css, SerializedStyles } from '@emotion/react';

export const waitFreeStyle = (size: number): SerializedStyles => css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${size}px;
  height: ${size}px;
  margin-left: 1px;
  transform: translate(-50%, -50%);
`;
