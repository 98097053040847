import { useContext } from 'react';

import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { underlineHoverStyle } from '@/components/styles';
import { EventParamsType, EventParamsValueType } from '@/utils/eventClient';
import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';

interface TrackingBookPublisherParams extends EventParamsType {
  book_id: EventParamsValueType;
  publisher_name: EventParamsValueType;
}
interface BookPublisherOptions {
  showAsLink: boolean;
}

export const BookPublisher = modularComponent(
  ({ showAsLink }) =>
    ({ className }) => {
      const {
        components: { BookDefaultMetadataItem },
        trackingData: sectionTrackingData,
      } = useContext(BookDefinitionContext);
      const bookData = useContext(BookDataContext);

      const publisher = bookData.metadata?.extra?.publisher;
      if (!publisher) {
        return <></>;
      }
      const publisherQuery = encodeURIComponent(`출판사:${publisher}`);
      const publisherHref = `/search?q=${publisherQuery}`;
      const publisherText = showAsLink ? (
        <a href={publisherHref} css={underlineHoverStyle}>
          {publisher}
        </a>
      ) : (
        <span>{publisher}</span>
      );

      const computedParams: TrackingBookPublisherParams = {
        book_id: bookData.bookId,
        publisher_name: publisher,
        ...sectionTrackingData?.params,
        ...bookData.trackingOptions?.extraParams,
      };
      const renderPublisherText = (): ReactJSX.Element => {
        if (!sectionTrackingData || bookData.trackingOptions?.disableTrackEvent || !showAsLink) {
          return <>{publisherText}</>;
        }
        return (
          <TrackClickEvent screenName={sectionTrackingData.screenName} target="publisher" params={computedParams}>
            {publisherText}
          </TrackClickEvent>
        );
      };

      return <BookDefaultMetadataItem className={className}>{renderPublisherText()}</BookDefaultMetadataItem>;
    },
  { showAsLink: true } as BookPublisherOptions,
);
