import { css, SerializedStyles } from '@emotion/react';

import { RIDITheme } from '@/components/styles/themes';
import { rgba } from '@/utils/rgba';

export const setbookWrapperStyle = (theme: RIDITheme): SerializedStyles => css`
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.06) 0%,
    rgba(0, 0, 0, 0) 5.73%,
    rgba(0, 0, 0, 0) 94.27%,
    rgba(0, 0, 0, 0.06) 100%
  );
  background-color: ${rgba(theme.colors.white, 0.87)};
  box-shadow: ${rgba(theme.colors.black, 0.15)} 0 1px 1px 0;
  font-weight: 700;
  color: ${rgba(theme.colors.black, 0.8)};

  position: absolute;
  left: 0;
  right: 0;
  text-align: center;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background: ${theme.colors.darkNavy};
    background-clip: content-box;
    opacity: 0.1;
  }

  &::before {
    transform-origin: top;
    top: 0;
    padding-top: 1px;
  }

  &::after {
    transform-origin: bottom;
    bottom: 0;
    padding-bottom: 1px;
  }
`;

export const setbookWrapperLineSizeStyle = (size: number): SerializedStyles => css`
  &::before,
  &::after {
    transform: scale(${size});
  }
`;

export const setbookWrapperPaddingStyle = (padding: string): SerializedStyles => css`
  padding: ${padding};
`;

export const setbookWrapperTextSizeStyle = (textSize: number): SerializedStyles => css`
  font-size: ${textSize}px;
`;

export const setbookWrapperLineHeightStyle = (lineHeight: number): SerializedStyles => css`
  line-height: ${lineHeight}px;
`;

export const setbookWrapperBottomStyle = (bottom: number): SerializedStyles => css`
  bottom: ${bottom}px;
`;
