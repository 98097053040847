import { ModularComponent, ModularResponsiveOption } from '@/utils/modularComponent';

import { RenewalBookSizeTypes, RenewalBookSizeTypeValues } from '../constants';
import * as coverComponents from '../RenewalBookCoverRenderer';
import * as metadataComponents from '../RenewalBookMetadataRenderer';
import * as rendererComponents from '../RenewalBookRenderer';
import * as infoComponents from '../RenewalInfoBookMetadataRenderer';
import { getRenewalSizeConstants } from '../utils/getRenewalSizeConstants';
import type { BookPreset, BookPresetComponents } from '.';

export const RenewalBookComponents = {
  // Cover Components
  BookAdultBadge: coverComponents.RenewalBookAdultBadge,
  BookAdultIndicator: coverComponents.RenewalBookAdultIndicator,
  BookBadge: coverComponents.RenewalBookBadge,
  BookBadgeRenderer: coverComponents.RenewalBookBadgeRenderer,
  BookBadgeText: coverComponents.RenewalBookBadgeText,
  BookCoverDimmer: coverComponents.RenewalBookCoverDimmer,
  BookCoverRenderer: coverComponents.RenewalBookCoverRenderer,
  BookDiscountBadge: coverComponents.RenewalBookDiscountBadge,
  BookFreebook: coverComponents.RenewalBookFreebook,
  BookFreebookBadge: coverComponents.RenewalBookFreebookBadge,
  BookImageBadge: coverComponents.RenewalBookImageBadge,
  BookNewBadge: coverComponents.RenewalBookNewBadge,
  BookOriginal: coverComponents.RenewalBookOriginal,
  BookPurchaseStatus: coverComponents.RenewalBookPurchaseStatus,
  BookRentalBadge: coverComponents.RenewalBookRentalBadge,
  BookSelectBadge: coverComponents.RenewalBookSelectBadge,
  BookSetbook: coverComponents.RenewalBookSetbook,
  BookThumbnail: coverComponents.RenewalBookThumbnail,
  BookThumbnailBorder: coverComponents.RenewalBookThumbnailBorder,
  BookThumbnailGradient: coverComponents.RenewalBookThumbnailGradient,
  BookWaitFreeBadge: coverComponents.RenewalBookWaitFreeBadge,

  // Metadata Components
  BookAuthors: metadataComponents.RenewalBookAuthors,
  BookAuthorItem: metadataComponents.RenewalBookAuthorItem,
  BookDefaultMetadataItem: metadataComponents.RenewalBookDefaultMetadataItem,
  BookMetadataItems: metadataComponents.RenewalBookMetadataItems,
  BookMetadataItemGroup: metadataComponents.RenewalBookMetadataItemGroup,
  BookMetadataRenderer: metadataComponents.RenewalBookMetadataRenderer,
  BookPrice: metadataComponents.RenewalBookPrice,
  BookPriceItem: metadataComponents.RenewalBookPriceItem,
  BookPriceInfoItem: metadataComponents.RenewalBookPriceInfoItem,
  BookPriceDiscountItem: metadataComponents.RenewalBookPriceDiscountItem,
  BookPriceAdditionalInfoItem: metadataComponents.RenewalBookPriceAdditionalInfoItem,
  BookSeries: metadataComponents.RenewalBookSeries,
  BookSeriesCompletion: metadataComponents.RenewalBookSeriesCompletion,
  BookStarRateMinimal: metadataComponents.RenewalBookStarRateMinimal,
  BookStarRateFull: metadataComponents.RenewalBookStarRateFull,
  BookStarRate: metadataComponents.RenewalBookStarRate,
  BookTitle: metadataComponents.RenewalBookTitle,

  // Renderer Components
  BookRenderer: rendererComponents.RenewalBookRenderer,
  BookTouchArea: rendererComponents.RenewalBookTouchArea,
};

// Type helper for RenewalBook Modular Options
type RenewalBookComponentRecords = typeof RenewalBookComponents;

export type RenewalBookComponentsType = RenewalBookComponentRecords &
  Omit<BookPresetComponents, keyof RenewalBookComponentRecords>;

export type RenewalBookPresetComponents = {
  [K in keyof RenewalBookComponentRecords]: RenewalBookComponentRecords[K] extends ModularComponent<infer O, infer P>
    ? ModularComponent<Partial<O>, P>
    : never;
} & BookPresetComponents;

export type RenewalBookConstants = {
  [K in keyof RenewalBookComponentsType]?: RenewalBookComponentsType[K]['withOptions'] extends (
    options: infer O,
  ) => RenewalBookComponentsType[K]
    ? { [RK in keyof O]: Required<O>[RK] extends ModularResponsiveOption<infer V> ? V : never }
    : never;
};

export const RenewalBookPreset: BookPreset = baseComponents => ({
  ...baseComponents,
  ...RenewalBookComponents,
});

/** @function applyConstantsToComponents
 *  @desc 리뉴얼 북으로 구성된 components와 constants로부터 constants의 반응형 옵션이 적용된 components를 만들어냄
 */
const applyConstantsToComponents = (
  components: RenewalBookPresetComponents,
  constants: RenewalBookConstants,
  typeOption: ModularResponsiveOption<unknown>[0],
) => {
  let presetComponents = { ...components };
  (Object.keys(constants) as (keyof RenewalBookPresetComponents)[]).forEach(componentName => {
    let component = presetComponents[componentName] as ModularComponent<Any, Any>;
    const options = constants[componentName];
    if (!options) {
      return;
    }

    Object.keys(options).forEach(key => {
      component = component.addResponsiveOption(key, {
        ...typeOption,
        value: options[key as keyof typeof options],
      });
    });

    presetComponents = { ...presetComponents, [componentName]: component };
  });

  return presetComponents;
};

export const RenewalBookCoverSizePreset =
  (responsiveType: ModularResponsiveOption<RenewalBookSizeTypeValues>): BookPreset =>
  components =>
    responsiveType.reduce<RenewalBookPresetComponents>(
      (presetComponents, typeOption) => {
        const sizeConstants = getRenewalSizeConstants(typeOption.value);
        if (!sizeConstants.isDefault) {
          return applyConstantsToComponents(presetComponents, sizeConstants.cover, typeOption);
        }

        return presetComponents;
      },
      { ...components } as RenewalBookPresetComponents,
    );

export const RenewalBookMetadataSizePreset =
  (responsiveType: ModularResponsiveOption<RenewalBookSizeTypeValues>): BookPreset =>
  components =>
    responsiveType.reduce<RenewalBookPresetComponents>(
      (presetComponents, typeOption) => {
        const sizeConstants = getRenewalSizeConstants(typeOption.value);
        if (!sizeConstants.isDefault) {
          return applyConstantsToComponents(presetComponents, sizeConstants.metadata, typeOption);
        }

        return presetComponents;
      },
      { ...components } as RenewalBookPresetComponents,
    );

type RenewalBookSize = { width: number; height: number; type?: RenewalBookSizeTypeValues };
export const RenewalBookRendererSizePreset =
  (responsiveSize: ModularResponsiveOption<RenewalBookSize>): BookPreset =>
  components => {
    const presetComponents = { ...components } as RenewalBookPresetComponents;
    presetComponents.BookRenderer = presetComponents.BookRenderer.withOptions({ type: responsiveSize });
    presetComponents.BookThumbnail = presetComponents.BookThumbnail.withOptions({ type: responsiveSize });

    return presetComponents;
  };

export const RenewalBookSizePreset =
  (responsiveSize: ModularResponsiveOption<RenewalBookSize>): BookPreset =>
  components => {
    const responsiveType = responsiveSize.map(({ value: { type = RenewalBookSizeTypes.DEFAULT }, ...sizeOption }) => ({
      ...sizeOption,
      value: type,
    }));

    const coverPresetComponents = RenewalBookCoverSizePreset(responsiveType)(components);
    const metadataPresetComponents = RenewalBookMetadataSizePreset(responsiveType)(coverPresetComponents);
    const rendererPresetComponents = RenewalBookRendererSizePreset(responsiveSize)(metadataPresetComponents);

    return rendererPresetComponents;
  };

const RenewalBookInfoLevelComponentMap = {
  2: {
    BookSkeleton: infoComponents.Renewal2InfoBookSkeleton,
    BookMetadataItems: infoComponents.Renewal2InfoBookMetadataItems,
    BookMetadataRenderer: infoComponents.Renewal2InfoBookMetadataRenderer,
    BookFastRenderer: infoComponents.Renewal2InfoBookFastRenderer,
  },

  3: {
    BookSkeleton: infoComponents.Renewal3InfoBookSkeleton,
    BookMetadataItems: infoComponents.Renewal3InfoBookMetadataItems,
    BookMetadataRenderer: infoComponents.Renewal3InfoBookMetadataRenderer,
    BookFastRenderer: infoComponents.Renewal3InfoBookFastRenderer,
  },

  5: {
    BookSkeleton: infoComponents.Renewal5InfoBookSkeleton,
    BookMetadataItems: infoComponents.Renewal5InfoBookMetadataItems,
    BookMetadataRenderer: infoComponents.Renewal5InfoBookMetadataRenderer,
    BookFastRenderer: infoComponents.Renewal5InfoBookFastRenderer,
  },
};

export const RenewalBookInfoLevelPreset =
  (infoLevel: 2 | 3 | 5): BookPreset =>
  components => {
    const presetComponents = { ...components } as RenewalBookPresetComponents;
    presetComponents.BookAuthors = presetComponents.BookAuthors.withOptions({
      clipCount: null,
      clipBreakPoint: Number.POSITIVE_INFINITY,
    });

    presetComponents.BookSkeleton = RenewalBookInfoLevelComponentMap[infoLevel].BookSkeleton;
    presetComponents.BookMetadataRenderer = RenewalBookInfoLevelComponentMap[infoLevel].BookMetadataRenderer;
    presetComponents.BookMetadataItems = RenewalBookInfoLevelComponentMap[infoLevel].BookMetadataItems;

    return presetComponents;
  };
