import { LegacyRef, MutableRefObject } from 'react';

type ReactRef<T> = MutableRefObject<T> | LegacyRef<T> | undefined;

export const mergeRefs =
  <T>(refs: ReactRef<T>[]) =>
  (node: T): void => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        const refValue = ref as MutableRefObject<T | null>;
        refValue.current = node;
      }
    });
  };
