import { css, SerializedStyles } from '@emotion/react';

export const originalGradientStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%;
    background: linear-gradient(130.07deg, rgba(0, 0, 0, 1) 12.26%, rgba(0, 0, 0, 0) 52.51%);
    opacity: 0.06;
  }
`;

export const originalGradientSizeStyle = (gradientSize: number): SerializedStyles => css`
  width: ${gradientSize}px;
`;

export const originalIconStyle = css`
  position: absolute;
`;

export const originalIconPositionStyle = (position: { x: number; y: number }): SerializedStyles => css`
  top: ${position.x}px;
  left: ${position.y}px;
`;

export const originalIconSizeStyle = (size: number): SerializedStyles => css`
  width: ${size}px;
`;
