import { css, SerializedStyles } from '@emotion/react';
import colors from '@ridi/colors';

export const setbookWrapperStyle = css`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const setbookWrapperLinkStyle = css`
  ${setbookWrapperStyle};
  cursor: pointer;
`;

export const setbookTextWrapperStyle = css`
  display: block;
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: 21%;
  width: calc(100% + 2px);
  padding: 1px 0;
  background: white;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  text-align: center;

  &::before {
    content: '';
    display: block;
    width: 100%;
    background: ${colors.dodgerBlue50};
    position: absolute;
    top: 50%;
    margin-top: -1px;
  }

  &::after {
    content: '';

    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 5%,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
`;

export const setbookTextWrapperBorderSizeStyle = (borderSize: number): SerializedStyles => css`
  &::before {
    height: ${borderSize}px;
  }
`;

export const setbookTextStyle = css`
  position: relative;
  display: inline-block;
  height: 1em;
  background: white;
  line-height: 1em;
  color: ${colors.gray80};
  box-sizing: content-box;
`;

export const setbookTextSizeStyle = (textSize: number): SerializedStyles => css`
  font-size: ${textSize}px;
`;

export const setbookTextPaddingStyle = (padding: string): SerializedStyles => css`
  padding: ${padding};
`;

export const setbookBorderStyle = css`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  border: 1px solid #000;
`;
