import { useTheme } from '@emotion/react';
import { useContext } from 'react';

import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { modularComponent } from '@/utils/modularComponent';

import * as styles from './RenewalBookImageBadge.styles';

export const RenewalBookImageBadge = modularComponent(
  () =>
    ({ className }) => {
      const { BookBadge } = useContext(BookDefinitionContext).components;
      const badges = useContext(BookDataContext).cover?.badges?.image;
      if (!badges) {
        return <></>;
      }

      const theme = useTheme();
      return (
        <>
          {badges
            .map((badge, index) => ({ ...badge, key: `${String(badge?.alt)}-${index}` }))
            .map(badge => (
              <BookBadge
                ariaLabel={badge?.alt}
                className={className}
                background={badge?.color?.[theme.colorScheme] ?? 'transparent'}
                key={badge.key}>
                {badge?.images
                  ?.map((src, index) => ({ src, key: index }))
                  .map(({ src, key }) => <img key={key} css={styles.imageBadgeStyle} src={src} alt="" />)}
              </BookBadge>
            ))}
        </>
      );
    },
  {},
);
