import { css } from '@emotion/react';
import colors from '@ridi/colors';

import { RidiColors } from '@/components/styles/RidiColors';

export const completionStyle = css`
  position: relative;
  top: -1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  padding: 3px 4px;
  margin-left: 4px;
  border-radius: 3px;
  background: ${colors.gray30};
  color: ${RidiColors.white};
  font-size: 10px;
  font-weight: bold;
`;

export const numberStyle = css`
  padding-top: 1px;
`;

export const textStyle = css`
  line-height: 1em;
  display: inline-block;
`;
