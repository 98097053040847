import {
  RenewalBookCoverConstantsDefault,
  RenewalBookCoverConstantsExtraSmall,
  RenewalBookCoverConstantsLarge,
  RenewalBookCoverConstantsSmall,
  RenewalBookMetadataConstantsDefault,
  RenewalBookMetadataConstantsExtraSmall,
  RenewalBookMetadataConstantsLarge,
  RenewalBookMetadataConstantsSmall,
  RenewalBookSizeTypes,
  RenewalBookSizeTypeValues,
} from '../../constants';

type NonDefaultRenewalSizeConstants = {
  cover:
    | typeof RenewalBookCoverConstantsExtraSmall
    | typeof RenewalBookCoverConstantsSmall
    | typeof RenewalBookCoverConstantsLarge;

  metadata:
    | typeof RenewalBookMetadataConstantsExtraSmall
    | typeof RenewalBookMetadataConstantsSmall
    | typeof RenewalBookMetadataConstantsLarge;

  isDefault: false;
};

type DefaultRenewalSizeConstants = {
  cover: typeof RenewalBookCoverConstantsDefault;
  metadata: typeof RenewalBookMetadataConstantsDefault;
  isDefault: true;
};

export const getRenewalSizeConstants = (
  sizeType: RenewalBookSizeTypeValues,
): DefaultRenewalSizeConstants | NonDefaultRenewalSizeConstants => {
  if (sizeType <= RenewalBookSizeTypes.EXTRA_SMALL) {
    return {
      cover: RenewalBookCoverConstantsExtraSmall,
      metadata: RenewalBookMetadataConstantsExtraSmall,
      isDefault: false,
    };
  }

  if (sizeType <= RenewalBookSizeTypes.SMALL) {
    return {
      cover: RenewalBookCoverConstantsSmall,
      metadata: RenewalBookMetadataConstantsSmall,
      isDefault: false,
    };
  }

  if (sizeType >= RenewalBookSizeTypes.LARGE) {
    return {
      cover: RenewalBookCoverConstantsLarge,
      metadata: RenewalBookMetadataConstantsLarge,
      isDefault: false,
    };
  }

  return {
    cover: RenewalBookCoverConstantsDefault,
    metadata: RenewalBookMetadataConstantsDefault,
    isDefault: true,
  };
};
