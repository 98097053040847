import { useContext } from 'react';

import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { modularComponent } from '@/utils/modularComponent';

export const RenewalBookNewBadge = modularComponent(() => ({ className }): ReactJSX.Element => {
  const { BookBadge } = useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const newRelease = bookData.cover?.badges?.newRelease;
  if (!newRelease) {
    return <></>;
  }

  return (
    <BookBadge background="mint" className={className}>
      UP
    </BookBadge>
  );
});
