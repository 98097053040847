import { css } from '@emotion/react';
import colors from '@ridi/colors';

export const metadataItemStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

export const metadataItemGroupStyle = css`
  display: flex;

  & > * {
    ${metadataItemStyle};
  }
`;

export const metadataItemGroupDividerStyle = css`
  & > * + * {
    &::before {
      content: '';

      display: inline-block;
      background: ${colors.gray10};
      width: 1px;
      height: 10px;
      margin: 0 8px;
    }
  }
`;

export const metadataItemGroupCollapseStyle = css`
  flex-direction: column;
`;
