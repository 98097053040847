import colors from '@ridi/colors';
import { useContext } from 'react';

import { IconWaitFree } from '@/assets/svgs';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext } from '../../BookDataContext';
import { BookDefinitionContext } from '../../BookDefinitionContext';
import * as styles from './BookWaitFreeBadge.styles';

export const BookWaitFreeBadge = modularComponent(
  ({ size }) => {
    const waitFreeStyle = modularResponsiveStyle(styles.waitFreeStyle, size);

    return ({ className }): ReactJSX.Element => {
      const { BookBadge } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);

      const waitFree = bookData.cover?.badges?.waitFree;
      if (!waitFree) {
        return <></>;
      }

      return (
        <BookBadge background={colors.dodgerBlue50} className={className}>
          <IconWaitFree css={waitFreeStyle} aria-label="기다리면 무료" />
        </BookBadge>
      );
    };
  },
  { size: [{ value: 20 }] as ModularResponsiveOption<number> },
);
