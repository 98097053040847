import { cloneElement, forwardRef, useMemo } from 'react';

import { sendViewEvent } from '@/utils/eventClient';
import { mergeRefs } from '@/utils/mergeRefs';

import { useViewEventObserverRef, useZeroThresholdViewEventObserverRef } from '../ViewEventObserver/ViewEventObserver';
import { TrackEventProps } from './types';

interface TrackViewEventProps extends TrackEventProps {
  threshold?: 0 | 0.5;
}

export const TrackViewEvent = forwardRef<Element, TrackViewEventProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, screenName, target, params, threshold }, ref) => {
    const defaultObservedRef = useViewEventObserverRef((): void => {
      sendViewEvent(screenName, target, params);
    });
    const zeroThresholdObservedRef = useZeroThresholdViewEventObserverRef((): void => {
      sendViewEvent(screenName, target, params);
    });
    const observedRef = threshold === 0 ? zeroThresholdObservedRef : defaultObservedRef;

    const mergedRef = useMemo(() => mergeRefs([observedRef, ref]), [ref, observedRef]);
    return cloneElement(children, { ref: mergedRef });
  },
);
