import { useTheme } from '@emotion/react';
import { useContext } from 'react';

import { IconReviewRate } from '@/assets/svgs/system';
import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { BookNothing } from '@/components/common/Book/BookNothing';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { RenewalBookConstantsDefault as constants } from '../constants';
import * as styles from './RenewalBookStarRate.styles';

export const RenewalBookStarRateMinimal = modularComponent(
  ({ size, textSize, lineHeight }) => {
    const starRateIconSizeStyle = modularResponsiveStyle(styles.starRateIconSizeStyle, size);
    const starRateWrapperTextSizeStyle = modularResponsiveStyle(styles.starRateWrapperTextSizeStyle, textSize);
    const starRateWrapperLineHeightStyle = modularResponsiveStyle(styles.starRateWrapperLineHeightStyle, lineHeight);

    return ({ className, children }) => {
      const { BookDefaultMetadataItem } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);

      const score = bookData.metadata?.starRate?.score;
      const count = bookData.metadata?.starRate?.count;

      if (typeof score !== 'number' || !Number.isFinite(score)) {
        return <></>;
      }
      const hasScore = score > 0;
      const scoreText = hasScore ? Math.min(5, Math.max(0, score)).toFixed(1) : '0';
      const label = hasScore ? '구매자 평균 별점' : '별점 정보 없음';

      const theme = useTheme();
      const starRateStyle = styles.starRateWrapperStyle(hasScore, theme);
      const starRateIconStyle = [styles.starRateIconStyle(hasScore, theme), starRateIconSizeStyle];

      return (
        <BookDefaultMetadataItem
          css={[starRateWrapperTextSizeStyle, starRateWrapperLineHeightStyle, starRateStyle]}
          className={className}>
          <IconReviewRate css={starRateIconStyle} aria-label={label} />
          {scoreText}
          {count !== undefined && count > 0 && <span css={styles.starRateCountStyle}>({count.toLocaleString()})</span>}
          {children}
        </BookDefaultMetadataItem>
      );
    };
  },
  {
    size: [{ value: constants.BookStarRate.size }] as ModularResponsiveOption<number>,
    textSize: [{ value: constants.BookStarRate.textSize }] as ModularResponsiveOption<number>,
    lineHeight: [{ value: constants.BookStarRate.lineHeight }] as ModularResponsiveOption<number>,
  },
);

export const RenewalBookStarRateFull = BookNothing;
export const RenewalBookStarRate = RenewalBookStarRateMinimal;
