import { LegacyRef, useContext } from 'react';

import { modularComponent, ModularComponentProps } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';
import { BookFastRenderer } from '../BookFastRenderer';
import { RenewalBookMetadataRenderer } from '../RenewalBookMetadataRenderer';
import * as styles from './RenewalInfoBookMetadataRenderer.styles';

type SkeletonProps = ModularComponentProps & { elementRef?: LegacyRef<HTMLDivElement> };

/*
 * 2Info
 */
export const Renewal2InfoBookSkeleton = modularComponent(() => ({ elementRef, className }: SkeletonProps) => (
  <div className={className} css={styles.renewal2InfoSkeletonStyle} ref={elementRef}>
    <div css={styles.renewalThumbnailSkeletonStyle} />
    <div css={styles.renewalTitleSkeletonStyle} />
    <div css={styles.renewalAuthorSkeletonStyle} />
  </div>
));

export const Renewal2InfoBookMetadataItems = modularComponent(() => ({ className, children }) => {
  const { BookMetadataItemGroup, BookAuthors } = useContext(BookDefinitionContext).components;

  return (
    <>
      <BookMetadataItemGroup className={className}>
        <BookAuthors css={styles.renewal2InfoAuthorsStyle} />
      </BookMetadataItemGroup>
      {children}
    </>
  );
});

export const Renewal2InfoBookMetadataRenderer = RenewalBookMetadataRenderer.addStyle(styles.renewal2InfoMetadataStyle);
export const Renewal2InfoBookFastRenderer = BookFastRenderer.addStyle(styles.renewal2InfoSkeletonStyle);

/*
 * 3Info
 */
export const Renewal3InfoBookSkeleton = modularComponent(() => ({ elementRef, className }: SkeletonProps) => (
  <div className={className} css={styles.renewal3InfoSkeletonStyle} ref={elementRef}>
    <div css={styles.renewalThumbnailSkeletonStyle} />
    <div css={styles.renewalTitleSkeletonStyle} />
    <div css={styles.renewalAuthorSkeletonStyle} />
    <div css={styles.renewalStarRateSkeletonStyle} />
  </div>
));

export const Renewal3InfoBookMetadataItems = modularComponent(() => ({ className, children }) => {
  const { BookAuthors, BookStarRate, BookMetadataItemGroup } = useContext(BookDefinitionContext).components;

  return (
    <>
      <BookMetadataItemGroup className={className}>
        <BookAuthors css={styles.renewal3InfoAuthorsStyle} />
        <BookStarRate css={styles.renewal3InfoStarRateStyle} />
      </BookMetadataItemGroup>
      {children}
    </>
  );
});

export const Renewal3InfoBookMetadataRenderer = RenewalBookMetadataRenderer.addStyle(styles.renewal3InfoMetadataStyle);
export const Renewal3InfoBookFastRenderer = BookFastRenderer.addStyle(styles.renewal3InfoSkeletonStyle);

/*
 * 5Info
 */
export const Renewal5InfoBookSkeleton = modularComponent(() => ({ className, elementRef }: SkeletonProps) => {
  // TODO add skeleton for Renewal5InfoBook
  const { metadata } = useContext(BookDataContext);
  return (
    <div
      ref={elementRef}
      css={styles.renewal5InfoSkeletonStyle(!!metadata?.extra?.series, !!metadata?.extra?.price)}
      className={className}
    />
  );
});

export const Renewal5InfoBookFastRenderer = modularComponent(
  options => {
    const BaseFastRenderer = BookFastRenderer.withOptions(options);

    return ({ children, ...props }) => {
      const { metadata } = useContext(BookDataContext);
      return (
        <BaseFastRenderer
          css={styles.renewal5InfoSkeletonStyle(!!metadata?.extra?.series, !!metadata?.extra?.price)}
          {...props}>
          {children}
        </BaseFastRenderer>
      );
    };
  },
  { ...BookFastRenderer.getOptions() },
);

export const Renewal5InfoBookMetadataItems = modularComponent(() => ({ className, children }) => {
  const { BookAuthors, BookSeries, BookStarRate, BookPrice, BookMetadataItemGroup } =
    useContext(BookDefinitionContext).components;

  return (
    <>
      <BookMetadataItemGroup className={className}>
        <BookAuthors css={styles.renewal5InfoAuthorsStyle} />
        <BookSeries css={styles.renewal5InfoSeriesStyle} />
        <BookStarRate css={styles.renewal5InfoStarRateStyle} />
        <BookPrice css={styles.renewal5InfoPriceStyle} />
      </BookMetadataItemGroup>
      {children}
    </>
  );
});

export const Renewal5InfoBookMetadataRenderer = modularComponent(
  ({ ...options }) => {
    const MetadataRenderer = RenewalBookMetadataRenderer.withOptions({ ...options });
    return ({ className, children }) => {
      const { metadata } = useContext(BookDataContext);

      return (
        <MetadataRenderer
          css={styles.renewal5InfoMetadataStyle(!!metadata?.extra?.series, !!metadata?.extra?.price)}
          className={className}>
          {children}
        </MetadataRenderer>
      );
    };
  },
  { ...RenewalBookMetadataRenderer.getOptions() },
);
