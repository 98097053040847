import { useContext } from 'react';

import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../../BookDataContext';
import { BookDefinitionContext } from '../../BookDefinitionContext';
import * as styles from './BookDeductionUnavailableTitleBadge.styles';

export const BookDeductionUnavailableTitleBadge = modularComponent(() => ({ className }): ReactJSX.Element => {
  const { BookTitleBadge } = useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const isDeductionUnavailable = bookData.metadata?.extra?.titleBadge?.isDeductionUnavailable;
  if (!isDeductionUnavailable) {
    return <></>;
  }

  return (
    <BookTitleBadge css={styles.deductionUnavailableWrapperStyle} className={className}>
      <span css={styles.deductionUnavailableTextStyle}>비공제 도서</span>
    </BookTitleBadge>
  );
});
