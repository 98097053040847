import { ReactNode } from 'react';

export const joinComponents = (components: ReactNode[], delimiter: string): ReactNode =>
  components.reduce<ReactNode[]>((reducedComponents, component, index, array) => {
    reducedComponents.push(component);

    if (index === array.length - 1) {
      return reducedComponents;
    }

    reducedComponents.push(delimiter);
    return reducedComponents;
  }, []);
