import { useContext } from 'react';

import { IconBadgeWaitFree } from '@/assets/svgs/system';
import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { modularComponent } from '@/utils/modularComponent';

export const RenewalBookWaitFreeBadge = modularComponent(() => ({ className }): ReactJSX.Element => {
  const { BookBadge } = useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const waitFree = bookData.cover?.badges?.waitFree;
  if (!waitFree) {
    return <></>;
  }

  return (
    <BookBadge background="blue" className={className}>
      <IconBadgeWaitFree aria-label="기다무" />
    </BookBadge>
  );
});
