import { css, SerializedStyles } from '@emotion/react';

export const bookStyle = (size: number): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${size}px;
`;

export const coverStyle =
  (fixedHeight: boolean) =>
  (size: number): SerializedStyles => css`
    ${fixedHeight &&
    css`
      display: flex;
      align-items: flex-end;
      height: ${Math.floor(size * 1.618 - 10)}px;
    `};
  `;

export const metadataStyle = css`
  width: 100%;
  margin-top: 6px;

  & > * {
    margin-top: 3px;
  }
`;
