import { useContext, useMemo } from 'react';

import { modularComponent } from '@/utils/modularComponent';
import { stripAllTags } from '@/utils/sanitize';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';
import * as styles from './BookDescription.styles';

export const BookDescriptionText = modularComponent(
  ({ maxChars, ellipsis }) =>
    ({ className, index = 0 }) => {
      const { BookLink } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);

      const description = bookData.metadata?.extra?.description ?? '';
      if (!description) {
        return <></>;
      }

      return (
        <BookLink css={styles.descriptionWrapperStyle} index={index}>
          <p className={className} css={styles.descriptionStyle}>
            {description.slice(0, maxChars)}
            {description.length > maxChars && ellipsis}
          </p>
        </BookLink>
      );
    },
  { maxChars: 160, ellipsis: '' },
);

export const BookDescriptionHTML = modularComponent(
  ({ maxChars, ellipsis }) =>
    ({ className, index = 0 }) => {
      const { BookLink } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);

      const description = bookData.metadata?.extra?.description ?? '';
      if (!description) {
        return <></>;
      }

      const descriptionText = useMemo(() => {
        const stripped = stripAllTags(description);
        if (stripped.length > maxChars) {
          return stripped.slice(0, maxChars) + ellipsis;
        }

        return stripped;
      }, [description]);

      return (
        <BookLink css={styles.descriptionWrapperStyle} index={index}>
          <p className={className} css={styles.descriptionStyle}>
            {descriptionText}
          </p>
        </BookLink>
      );
    },
  { maxChars: 160, ellipsis: '' },
);

export const BookDescription = BookDescriptionHTML;
