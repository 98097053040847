import { css } from '@emotion/react';
import { useContext } from 'react';

import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { modularComponent } from '@/utils/modularComponent';

export const discountBadgeStyle = css`
  padding: 0 2.5px;
`;

export const RenewalBookDiscountBadge = modularComponent(() => ({ className }): ReactJSX.Element => {
  const { BookBadge } = useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const discount = bookData.cover?.badges?.discount;
  if (!discount) {
    return <></>;
  }

  return (
    <BookBadge background="red" css={discountBadgeStyle} className={className}>
      {discount}%
    </BookBadge>
  );
});
