import { css } from '@emotion/react';
import colors from '@ridi/colors';

export const deductionUnavailableWrapperStyle = css`
  background: ${colors.lightSteelBlue20};
  height: 20px;
  line-height: 20px;
  padding: 0 5px;
  vertical-align: top;
`;

export const deductionUnavailableTextStyle = css`
  color: $ colors.lightSteelBlue90};
  font-size: 9px;
  font-weight: 700;
  vertical-align: top;
`;
