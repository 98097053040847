import { ModularResponsiveOption } from '@/utils/modularComponent';
import ADULT_COVER from '~/public/images/cover_adult.png';
import LAZYLOAD_COVER from '~/public/images/cover_lazyload.png';
import TRANSPARENT_COVER from '~/public/images/cover_transparent.png';

export { ADULT_COVER, LAZYLOAD_COVER, TRANSPARENT_COVER };

export type BookThumbnailType = 'small' | 'large' | 'xlarge' | 'xxlarge';
export type BookThumbnailDPIType = 'xhdpi' | 'xxhdpi';
export interface BookThumbnailParam {
  width: number;
  type: BookThumbnailType;
  dpi?: BookThumbnailDPIType;
}

export type BookThumbnailData = {
  [key in BookThumbnailType]?: string;
};

const DEFAULT_THUMBNAIL_URL = 'https://img.ridicdn.net/cover/';
const DEFAULT_THUMBNAIL_PARAMS: BookThumbnailParam[] = [
  { width: 90, type: 'small' },
  { width: 120, type: 'small', dpi: 'xhdpi' },
  { width: 165, type: 'large' },
  { width: 180, type: 'small', dpi: 'xxhdpi' },
  { width: 220, type: 'large', dpi: 'xhdpi' },
  { width: 225, type: 'xlarge' },
  { width: 300, type: 'xlarge', dpi: 'xhdpi' },
  { width: 330, type: 'large', dpi: 'xxhdpi' },
  { width: 450, type: 'xlarge', dpi: 'xxhdpi' },
  { width: 480, type: 'xxlarge' },
  { width: 640, type: 'xxlarge', dpi: 'xhdpi' },
  { width: 960, type: 'xxlarge', dpi: 'xxhdpi' },
];
export { DEFAULT_THUMBNAIL_PARAMS, DEFAULT_THUMBNAIL_URL };

export const setDPIQuery = (defaultURL: string, dpiQuery?: string): string => {
  const [hashBaseURL, hash = ''] = defaultURL.split('#');
  const [baseURL, queriesRaw = ''] = hashBaseURL.split('?');
  const queries = queriesRaw
    .split('&')
    .filter(query => query && !query.startsWith('dpi='))
    .concat(dpiQuery ? `dpi=${dpiQuery}` : [])
    .join('&');

  const urlQueries = queries ? `?${queries}` : '';
  const urlHash = hash ? `#${hash}` : '';

  return `${baseURL}${urlQueries}${urlHash}`;
};

export const getImageUrl = (
  thumbnailParam: BookThumbnailParam,
  thumbnailId?: string,
  thumbnails?: BookThumbnailData,
): string | null => {
  let imageURL: string | null = null;

  if (typeof thumbnails?.[thumbnailParam.type] === 'string') {
    imageURL = thumbnails[thumbnailParam.type] as string;
  }

  if (thumbnailId) {
    imageURL = `${DEFAULT_THUMBNAIL_URL}${thumbnailId}/${thumbnailParam.type}#1`;
  }

  if (!imageURL) {
    return null;
  }

  return setDPIQuery(imageURL, thumbnailParam.dpi);
};

export const getImageUrlForWidth = (
  size: number,
  availableThumbnails: BookThumbnailParam[],
  thumbnailId?: string,
  thumbnails?: BookThumbnailData,
): string | null => {
  const thumbnailParam =
    availableThumbnails.find(({ width }) => width >= size) || availableThumbnails[availableThumbnails.length - 1];

  return getImageUrl(thumbnailParam, thumbnailId, thumbnails);
};

export const getImageSizes = (size: ModularResponsiveOption<number>): string =>
  size
    .map(option => {
      const queries: string[] = [];
      if (option.orBelow) {
        queries.push(`(max-width: ${option.orBelow}px)`);
      }

      if (option.greaterThan) {
        queries.push(`(min-width: ${option.greaterThan + 1}px)`);
      }

      if (queries.length) {
        return `${queries.join(' and ')} ${option.value}px`;
      }

      return `${option.value}px`;
    })
    .join(', ');

export const getImageSrcSet = (
  availableThumbnails: BookThumbnailParam[],
  thumbnailId?: string,
  thumbnails?: BookThumbnailData,
): string =>
  availableThumbnails
    .map(param => ({
      width: param.width,
      url: getImageUrl(param, thumbnailId, thumbnails),
    }))
    .filter<{ width: number; url: string }>((item): item is { width: number; url: string } => !!item.url)
    .filter(({ url }, index, array) => array.findIndex(({ url: anotherUrl }) => anotherUrl === url) === index)
    .map(({ width, url }) => `${url} ${width}w`)
    .join(', ');
