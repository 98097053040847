import { css, SerializedStyles } from '@emotion/react';

import { lineClamp } from '@/components/styles';
import { RIDITheme } from '@/components/styles/themes';

export const titleTextStyle = (theme: RIDITheme): SerializedStyles => css`
  font-weight: 500;
  color: ${theme.colors.fillPrimary};
  letter-spacing: -0.01em;
`;

export const titleTextSizeStyle = (textSize: number): SerializedStyles => css`
  font-size: ${textSize}px;
`;

export const titleTextLineHeightStyle =
  (lineCount: number) =>
  (lineHeight: number): SerializedStyles => css`
    ${lineClamp(lineCount, `${lineHeight}px`)};
    word-break: break-all;
  `;
