import { useContext, useEffect, useRef, useState } from 'react';

import { useLoggedUser } from '@/hooks/useLoggedUser';
import {
  getDefaultResponsiveValue,
  modularComponent,
  ModularResponsiveOption,
  modularResponsiveStyle,
} from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';
import { useBookLazyLoadObserverRef } from '../BookLazyLoadObserver';
import { useThumbnailSrc } from '../hooks/useThumbnailSrc';
import { ADULT_COVER, getImageSizes, LAZYLOAD_COVER } from '../utils/thumbnail';
import * as styles from './BookThumbnail.styles';

export const BookThumbnailBorder = modularComponent(() => ({ children, ...props }) => (
  <span css={styles.thumbnailBorderStyle} {...props}>
    {children}
  </span>
));

export const BookThumbnailGradient = modularComponent(() => ({ children, ...props }) => (
  <div css={styles.thumbnailGradientStyle} {...props}>
    {children}
  </div>
));

interface BookThumbnailOptions {
  size: ModularResponsiveOption<number>;
  lazyload: boolean;
}

export const BookThumbnail = modularComponent<BookThumbnailOptions>(
  ({ size, lazyload }) => {
    const thumbnailWrapperStyle = modularResponsiveStyle(styles.thumbnailWrapperStyle, size);
    const thumbnailImageStyle = modularResponsiveStyle(styles.thumbnailImageStyle, size);
    const imageSizes = getImageSizes(size);
    const defaultWidth = getDefaultResponsiveValue(size);

    return ({ className, children }) => {
      const bookData = useContext(BookDataContext);
      const [isImageLoaded, setIsImageLoaded] = useState(false);
      const [isImageLazyLoaded, setIsImageLazyLoaded] = useState(!lazyload);

      const isUserVerifiedAdult = useLoggedUser()?.isVerifiedAdult;
      const isAdultOnly = bookData.cover?.isAdultOnly;
      const showAdultCover = isAdultOnly && !isUserVerifiedAdult;

      const { src: thumbnailSrc, srcSet: thumbnailSrcSet } = useThumbnailSrc(defaultWidth, isImageLazyLoaded);
      const src = showAdultCover ? ADULT_COVER.src : thumbnailSrc;
      const srcSet = showAdultCover ? undefined : thumbnailSrcSet;
      const isFallbackCover = !src;

      const viewRef = useBookLazyLoadObserverRef<HTMLDivElement>(() => setIsImageLazyLoaded(true));
      const ref = lazyload ? viewRef : null;

      const imageRef = useRef<HTMLImageElement>(null);
      useEffect(() => {
        setIsImageLoaded(!!imageRef.current?.complete);
      }, [src]);

      const { BookThumbnailGradient: ThumbnailGradient, BookThumbnailBorder: ThumbnailBorder } =
        useContext(BookDefinitionContext).components;

      return (
        <div css={thumbnailWrapperStyle} className={className} ref={ref}>
          <div css={styles.thumbnailStyle}>
            <img
              css={[
                thumbnailImageStyle,
                (isFallbackCover || !isImageLoaded || !isImageLazyLoaded) && styles.thumbnailUnloadedStyle,
              ]}
              src={src ?? LAZYLOAD_COVER.src}
              srcSet={srcSet}
              sizes={imageSizes}
              alt={bookData.title ?? ''}
              ref={imageRef}
              onLoad={() => setIsImageLoaded(true)}
            />
            <ThumbnailGradient />
            <ThumbnailBorder />
          </div>

          {children}
        </div>
      );
    };
  },
  { size: [{ value: 80 }], lazyload: false },
);

export type { BookThumbnailType } from '../utils/thumbnail';
