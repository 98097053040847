import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import * as styles from './BookDefaultMetadataItem.styles';

export const BookDefaultMetadataItem = modularComponent(
  ({ textSize }) => {
    const defaultMetadataItemStyle = [
      styles.defaultMetadataItemStyle,
      modularResponsiveStyle(styles.defaultMetadataItemSizeStyle, textSize),
    ];

    return ({ className, children }) => (
      <p css={defaultMetadataItemStyle} className={className}>
        {children}
      </p>
    );
  },
  { textSize: [{ value: 12 }] as ModularResponsiveOption<number> },
);
