import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';

export const metadataStyle = css`
  width: 100%;
`;

export const metadataItemMarginStyle = css`
  margin-top: 4px;
`;

export const metadataItemStyle = css`
  position: relative;
  display: flex;
  align-items: center;
`;

export const metadataItemGroupStyle = css`
  display: flex;

  & > * {
    ${metadataItemStyle};
  }
`;

export const metadataItemGroupMarginStyle = (size: number): SerializedStyles => css`
  & > * + * {
    margin-top: ${size}px;
  }
`;

export const metadataItemGroupDividerStyle = css`
  & > * + * {
    &::before {
      content: '';

      display: inline-block;
      background: currentColor;
      width: 2px;
      height: 2px;
      margin: 0 5px;
      border-radius: 100%;

      ${orBelow(
        BreakPoint.DesktopSmall,
        css`
          width: 1.5px;
          height: 1.5px;
          margin: 0 4.5px 0 4px;
        `,
      )}
    }
  }
`;

export const metadataItemGroupCollapseStyle = css`
  flex-direction: column;
`;
