import { useContext } from 'react';

import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { modularComponent } from '@/utils/modularComponent';

export const RenewalBookRentalBadge = modularComponent(() => ({ className }): ReactJSX.Element => {
  const { BookBadge } = useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const rental = bookData.cover?.badges?.rental;
  if (!rental) {
    return <></>;
  }

  return (
    <BookBadge background="darkNavy" className={className}>
      대여
    </BookBadge>
  );
});
