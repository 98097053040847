import { BookNothing } from '@/components/common/Book/BookNothing';

export * from './RenewalBookAdultBadge';
export * from './RenewalBookBadgeRenderer';
export * from './RenewalBookCoverRenderer';
export * from './RenewalBookOriginal';
export * from './RenewalBookSetbook';
export * from './RenewalBookThumbnail';
export const RenewalBookFreebook = BookNothing;
export const RenewalBookPurchaseStatus = BookNothing;
