import { css, SerializedStyles } from '@emotion/react';

import { RIDITheme } from '@/components/styles/themes';

export const badgeBackgroundStyle = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: ${theme.colors.white};
  font-weight: 700;
  letter-spacing: -0.02em;
  white-space: nowrap;
`;

export const badgeWrapperSizeStyle = (sizeValue: number): SerializedStyles => css`
  min-width: ${sizeValue}px;
  height: ${sizeValue}px;
`;

export const badgeWrapperContentSizeStyle = (sizeValue: number): SerializedStyles => css`
  font-size: ${sizeValue}px;
`;

export const badgeWrapperIconSizeStyle = (sizeValue: number): SerializedStyles => css`
  & > svg {
    font-size: ${sizeValue}px;
  }
`;
