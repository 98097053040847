import { ReactNode } from 'react';

import { modularComponent } from '@/utils/modularComponent';

import * as styles from './BookTitleBadge.styles';

interface BookTitleBadgeProps {
  className?: string;
  children?: ReactNode;
}

export const BookTitleBadge = modularComponent(
  () =>
    ({ className, children }: BookTitleBadgeProps): ReactJSX.Element => (
      <p css={styles.titleBadgeWrapperStyle} className={className}>
        {children}
      </p>
    ),
);
