import { useContext } from 'react';

import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import * as styles from './BookPurchaseStatus.styles';

export type BookPurchaseStatusType = 'purchased' | 'rent' | 'rent_finished' | null;

const PURCHASE_STATUS_TEXT: { [K in Exclude<BookPurchaseStatusType, null>]: string } = {
  purchased: '구매한 책',
  rent: '대여중',
  rent_finished: '대여했던 책',
};

const getPurchaseStatus = (status: BookPurchaseStatusType | undefined): string | null => {
  if (typeof status !== 'string' || !Object.hasOwnProperty.call(PURCHASE_STATUS_TEXT, status)) {
    return null;
  }

  return PURCHASE_STATUS_TEXT[status];
};

export const BookPurchaseStatus = modularComponent(() => ({ className }): ReactJSX.Element => {
  const bookData = useContext(BookDataContext);

  const status = getPurchaseStatus(bookData.cover?.extra?.purchaseStatus);
  if (typeof status !== 'string') {
    return <></>;
  }

  return (
    <p css={styles.purchaseStatusWrapperStyle} className={className}>
      <span css={styles.purchaseStatusTextStyle}>{status}</span>
    </p>
  );
});
