import { useContext } from 'react';

import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDefinitionContext } from '../BookDefinitionContext';
import { BookRenderer } from '../BookRenderer';
import { RenewalBookConstantsDefault as constants } from '../constants';
import * as styles from './RenewalBookRenderer.styles';

export const RenewalBookRenderer = modularComponent(
  ({ metadataMargin, metadataPaddingRight, type }) => {
    const bookStyle = [styles.bookStyle, modularResponsiveStyle(styles.bookTypeStyle, type)];
    const coverWrapperStyle = [styles.coverWrapperStyle, modularResponsiveStyle(styles.coverWrapperTypeStyle, type)];
    const metadataStyle = [
      modularResponsiveStyle(styles.metadataMarginStyle, metadataMargin),
      modularResponsiveStyle(styles.metadataPaddingRightStyle, metadataPaddingRight),
    ];

    return ({ className, children, index }) => {
      const { BookCoverRenderer, BookMetadataRenderer, BookTouchArea, BookViewTracker } =
        useContext(BookDefinitionContext).components;

      return (
        <BookViewTracker>
          <div css={bookStyle} className={className}>
            <div css={coverWrapperStyle}>
              <BookCoverRenderer css={styles.coverStyle} index={index} />
            </div>
            <BookMetadataRenderer css={metadataStyle} index={index}>
              {children}
            </BookMetadataRenderer>
            <BookTouchArea />
          </div>
        </BookViewTracker>
      );
    };
  },
  {
    ...BookRenderer.getOptions(),
    type: [{ value: { width: 120, height: 174 } }] as ModularResponsiveOption<{ width: number; height: number }>,
    metadataMargin: [{ value: constants.BookRenderer.metadataMargin }] as ModularResponsiveOption<number>,
    metadataPaddingRight: [{ value: constants.BookRenderer.metadataPaddingRight }] as ModularResponsiveOption<number>,
  },
);

export const RenewalBookTouchArea = modularComponent(
  () =>
    ({ className, children, index }) => {
      const { BookLink } = useContext(BookDefinitionContext).components;
      return (
        <BookLink css={styles.touchAreaStyle} className={className} index={index}>
          {children}
        </BookLink>
      );
    },
  {},
);
