import { css, SerializedStyles } from '@emotion/react';
import { StyledComponent } from '@emotion/styled';

export const overlayStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const coverStyle = (dimmerTarget: StyledComponent<Record<string, unknown>>): SerializedStyles => css`
  position: relative;
  display: block;

  &:hover ${dimmerTarget} {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
      linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.15) 100%);
  }
`;
