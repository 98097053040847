import { css, SerializedStyles } from '@emotion/react';

export const indicatorWrapperBaseStyle = css`
  position: absolute;

  /** dark mode에서 색상 반전 이슈 처리 */
  color-scheme: only light;
`;

export const indicatorWrapperSizeStyle = (size: number): SerializedStyles => css`
  width: ${size}px;
  height: ${size}px;
`;

export const indicatorWrapperOffsetStyle = (offset: number): SerializedStyles => css`
  top: ${offset}px;
  right: ${offset}px;
`;
