import colors from '@ridi/colors';
import { useContext } from 'react';

import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext } from '../../BookDataContext';
import { BookDefinitionContext } from '../../BookDefinitionContext';
import * as styles from './BookDiscountBadge.styles';

export const BookDiscountBadge = modularComponent(
  ({ numberSize, percentSize }) => {
    const discountNumberStyle = modularResponsiveStyle(styles.discountNumberStyle, numberSize);
    const discountPercentStyle = modularResponsiveStyle(styles.discountPercentStyle, percentSize);

    return ({ className }): ReactJSX.Element => {
      const { BookBadge, BookBadgeText } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);

      const discountPercentage = bookData.cover?.badges?.discount;
      if (!discountPercentage) {
        return <></>;
      }

      return (
        <BookBadge background={colors.blueGray40} className={className}>
          <BookBadgeText>
            <span css={discountNumberStyle}>{discountPercentage}</span>
            <span css={discountPercentStyle}>%</span>
          </BookBadgeText>
        </BookBadge>
      );
    };
  },
  {
    numberSize: [{ value: 16 }] as ModularResponsiveOption<number>,
    percentSize: [{ value: 10 }] as ModularResponsiveOption<number>,
  },
);
