import { useContext } from 'react';

import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { BookSeriesCompletion } from '@/components/common/Book/BookMetadataRenderer/BookSeries';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { RenewalBookConstantsDefault as constants } from '../constants';
import * as styles from './RenewalBookSeries.styles';

export const RenewalBookSeriesCompletion = modularComponent(
  ({ textSize, lineHeight }) => {
    const completionStyle = [
      styles.completionStyle,
      modularResponsiveStyle(styles.completionTextSizeStyle, textSize),
      modularResponsiveStyle(styles.completionLineHeightStyle, lineHeight),
    ];

    return BookSeriesCompletion.addStyle(completionStyle);
  },
  {
    textSize: [{ value: constants.BookSeriesCompletion.textSize }] as ModularResponsiveOption<number>,
    lineHeight: [{ value: constants.BookSeriesCompletion.lineHeight }] as ModularResponsiveOption<number>,
  },
);

export const RenewalBookSeries = modularComponent(() => ({ className, children }) => {
  const { BookDefaultMetadataItem, BookSeriesCompletion: BookContextSeriesCompletion } =
    useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const series = bookData.metadata?.extra?.series;
  if (!series) {
    return <></>;
  }

  if (typeof series.total !== 'number' || !Number.isInteger(series.total) || series.total < 1) {
    return <></>;
  }

  const unit = series.unit ?? '화';

  return (
    <BookDefaultMetadataItem className={className}>
      <span>{`총 ${series.total}${unit}`}</span>
      <BookContextSeriesCompletion />
      {children}
    </BookDefaultMetadataItem>
  );
});
