import { css, SerializedStyles } from '@emotion/react';

export const iconStyle = css`
  transform: translate(0, 0.5px);
  position: absolute;
  top: 0;
  right: 0;

  /** dark mode에서 색상 반전 이슈 처리 */
  color-scheme: only light;
`;

export const iconSizeStyle = (iconSize: number): SerializedStyles => css`
  font-size: ${iconSize}px;
`;

export const positionStyle = (position: number): SerializedStyles => css`
  top: ${position}px;
  right: ${position}px;
`;
