import { useContext } from 'react';

import { IconSomedeal } from '@/assets/svgs';
import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../../BookDataContext';
import { BookDefinitionContext } from '../../BookDefinitionContext';
import * as styles from './BookSomedealTitleBadge.styles';

/**
 * @deprecated 썸딜 이벤트가 끝난지 오래되어 현재는 거의 사용되고 있지 않음
 */
export const BookSomedealTitleBadge = modularComponent(() => ({ className }): ReactJSX.Element => {
  const { BookTitleBadge } = useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const isSomedeal = bookData.metadata?.extra?.titleBadge?.isSomedeal;
  if (!isSomedeal) {
    return <></>;
  }

  return (
    <BookTitleBadge css={styles.somedealBadgeStyle} className={className}>
      <IconSomedeal aria-label="썸딜도서" />
    </BookTitleBadge>
  );
});
