import { SerializedStyles } from '@emotion/react';
import { ReactNode } from 'react';

import { RIDITheme } from '@/components/styles/themes';

import { ModularResponsiveOption } from './modularResponsiveStyle';

export interface ModularComponentProps {
  className?: string;
  children?: ReactNode;
  index?: number;
}

type FilterResponsiveOption<O, K> = K extends keyof O
  ? Required<O>[K] extends ModularResponsiveOption<unknown>
    ? K
    : never
  : never;

type ModularStyles = SerializedStyles | ((theme: RIDITheme) => SerializedStyles) | ModularStyles[];

export interface ModularComponent<O, P extends ModularComponentProps = ModularComponentProps> {
  (props: P): ReactNode;
  getOptions(): O;
  addResponsiveOption<Key extends Extract<keyof O, FilterResponsiveOption<O, keyof O>>>(
    key: Key,
    option: Required<O>[Key] extends (infer R)[] ? R : never,
  ): ModularComponent<O, P>;
  addResponsiveOption<Key extends string>(
    key: Exclude<Key, Extract<keyof O, FilterResponsiveOption<O, keyof O>>>,
    option: ModularResponsiveOption<unknown>[0],
  ): ModularComponent<O, P>;
  addStyle(style: ModularStyles): ModularComponent<O, P>;
  withOptions(additionalOptions: Partial<O>): ModularComponent<O, P>;
}

export const modularComponent = <
  O extends Record<string, Any> = Record<never, never>,
  P extends ModularComponentProps = ModularComponentProps,
>(
  componentFactory: (options: O) => (props: P) => ReactNode,
  defaultOptions: O = {} as O,
  styles: ModularStyles[] = [],
): ModularComponent<O, P> => {
  const options = defaultOptions;

  const BaseComponent = componentFactory(options);
  const Component = ((props: P): ReactJSX.Element => (
    <BaseComponent css={styles} {...props}>
      {props.children}
    </BaseComponent>
  )) as ModularComponent<O, P>;

  Component.addStyle = style => {
    const newStyles = [...styles, style];
    return modularComponent<O, P>(componentFactory, options, newStyles);
  };

  Component.addResponsiveOption = (key: string, option: unknown) => {
    const newOptions = {
      ...options,
      [key]: [...((options[key] ?? []) as unknown[]), option],
    };

    return modularComponent<O, P>(componentFactory, newOptions, styles);
  };

  Component.withOptions = additionalOptions => {
    const newOptions = {
      ...options,
      ...additionalOptions,
    };

    return modularComponent<O, P>(componentFactory, newOptions, styles);
  };

  Component.getOptions = () => options;

  return Component;
};
