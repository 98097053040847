import { css, SerializedStyles } from '@emotion/react';

import { RIDITheme } from '@/components/styles/themes';

export const completionStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.fillSecondary};
  color: ${theme.colors.white};
  border-radius: 2px;
  padding: 0;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  letter-spacing: -0.02em;
  top: 0;
`;

export const completionLineHeightStyle = (lineHeight: number): SerializedStyles => css`
  line-height: ${lineHeight}px;
`;

export const completionTextSizeStyle = (textSize: number): SerializedStyles => css`
  ${textSize <= 9
    ? `
    font-size: 0;
    width: 22px;
    height: 14px;

    &::after {
      content: '완결';
      font-size: 10px;
      transform-origin: center center;
      transform: scale(calc(${textSize}/10));
    }
  `
    : `
    font-size: ${textSize}px;
    width: 25px;
    height: 16px;

    &::after {
      content: '';
    }
  `}
`;
