/* Size Type & its optimal size */
export const RenewalBookSizeTypes = {
  // ExtraSmall: 60 x 87
  EXTRA_SMALL: 100,

  // Small: 80 x 116
  SMALL: 300,

  // Default: 120 x 174
  DEFAULT: 400,

  // Large: 180 x 261
  LARGE: 700,
} as const;

export type RenewalBookSizeTypeValues = (typeof RenewalBookSizeTypes)[keyof typeof RenewalBookSizeTypes];

/*
 * ExtraSmall
 */
export const RenewalBookCoverConstantsExtraSmall = {
  BookAdultBadge: {
    iconSize: 16,
    position: 3,
  },

  BookOriginal: {
    iconSize: 41,
    iconPosition: { x: 2, y: 2 },
  },
} as const;

export const RenewalBookMetadataConstantsExtraSmall = {
  BookStarRate: {
    size: 11,
    textSize: 12,
    lineHeight: 13,
  },

  BookSetbook: {
    lineSize: 1,
    textSize: 7,
    lineHeight: 8,
    padding: '3px 5px',
    bottom: 14,
  },
} as const;

export const RenewalBookConstantsExtraSmall = {
  ...RenewalBookCoverConstantsExtraSmall,
  ...RenewalBookMetadataConstantsExtraSmall,
} as const;

/*
 * Small
 */
export const RenewalBookCoverConstantsSmall = {
  BookOriginal: {
    iconSize: 54,
    iconPosition: { x: 3, y: 3 },
  },

  BookSetbook: {
    lineSize: 1.38,
    textSize: 9,
    lineHeight: 11,
    padding: '5px 5px 6px 5px',
    bottom: 20,
  },
} as const;

export const RenewalBookMetadataConstantsSmall = {} as const;

export const RenewalBookConstantsSmall = {
  ...RenewalBookCoverConstantsSmall,
  ...RenewalBookMetadataConstantsSmall,
} as const;

/*
 * Default
 */
export const RenewalBookCoverConstantsDefault = {
  BookAdultBadge: {
    iconSize: 20,
    position: 4,
  },

  BookBadge: {
    size: 23,
    contentSize: 9,
    iconSize: 13,
  },

  BookOriginal: {
    iconSize: 67,
    iconPosition: { x: 5, y: 7 },
    gradientHeight: 100,
  },

  BookSetbook: {
    lineSize: 1.5,
    textSize: 10,
    lineHeight: 12,
    padding: '6px 5px 5px 5px',
    bottom: 27,
  },
} as const;

export const RenewalBookMetadataConstantsDefault = {
  BookDefaultMetadataItem: {
    textSize: 11,
    lineHeight: 13,
  },

  BookAuthors: {
    textSize: 13,
    lineHeight: 16,
  },

  BookStarRate: {
    size: 11,
    textSize: 12,
    lineHeight: 14,
  },

  BookTitle: {
    textSize: 15,
    lineHeight: 18,
  },

  BookRenderer: {
    metadataMargin: 6,
    metadataPaddingRight: 8,
  },

  BookSeriesCompletion: {
    textSize: 9,
    lineHeight: 11,
  },

  BookPriceItem: {
    margin: 2,
    textSize: 11,
    lineHeight: 13,
  },
} as const;

export const RenewalBookConstantsDefault = {
  ...RenewalBookCoverConstantsDefault,
  ...RenewalBookMetadataConstantsDefault,
} as const;

/*
 * Large
 */
export const RenewalBookCoverConstantsLarge = {
  BookAdultBadge: {
    iconSize: 24,
    position: 6,
  },

  BookBadge: {
    size: 28,
    contentSize: 11,
    iconSize: 18,
  },

  BookOriginal: {
    iconSize: 101,
    iconPosition: { x: 7, y: 10 },
    gradientHeight: 150,
  },

  BookSetbook: {
    lineSize: 2,
    textSize: 13,
    lineHeight: 16,
    padding: '8px',
    bottom: 34,
  },
} as const;

export const RenewalBookMetadataConstantsLarge = {
  BookDefaultMetadataItem: {
    textSize: 13,
    lineHeight: 16,
  },

  BookAuthors: {
    textSize: 14,
    lineHeight: 17,
  },

  BookTitle: {
    textSize: 16,
    lineHeight: 19,
  },

  BookRenderer: {
    metadataMargin: 10,
    metadataPaddingRight: 10,
  },

  BookSeriesCompletion: {
    textSize: 11,
    lineHeight: 13,
  },

  BookPriceItem: {
    margin: 3,
    textSize: 13,
    lineHeight: 16,
  },
} as const;

export const RenewalBookConstantsLarge = {
  ...RenewalBookCoverConstantsLarge,
  ...RenewalBookMetadataConstantsLarge,
} as const;
