import { css } from '@emotion/react';
import { useContext } from 'react';

import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { modularComponent } from '@/utils/modularComponent';

export const freebookBadgeStyle = css`
  padding: 0 5px;
`;

export const RenewalBookFreebookBadge = modularComponent(() => ({ className }): ReactJSX.Element => {
  const { BookBadge } = useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const shouldShowFreeBook = bookData.cover?.badges?.freeBook;
  const freeBookAmount = bookData.cover?.freeBook?.count;
  if (!shouldShowFreeBook || !freeBookAmount) {
    return <></>;
  }

  const freeBookUnit = bookData.cover?.freeBook?.unit ?? '권';
  const freeText = freeBookUnit.length >= 2 ? '무료' : '무';

  return (
    <BookBadge background="darkNavy" css={freebookBadgeStyle} className={className}>
      {freeBookAmount}
      {freeBookUnit}
      {freeText}
    </BookBadge>
  );
});
