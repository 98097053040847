import { useCallback, useContext, useState } from 'react';

import { modularComponent, ModularComponentProps } from '@/utils/modularComponent';

import { BookDefinitionContext } from './BookDefinitionContext';
import { useBookLazyLoadObserverRef } from './BookLazyLoadObserver';

export const BookFastRenderer = modularComponent(
  ({ useSkeleton }) =>
    ({ className, children, index }: ModularComponentProps) => {
      const { BookRenderer, BookSkeleton } = useContext(BookDefinitionContext).components;

      const [isRendered, setIsRendered] = useState(false);
      const onLazyload = useCallback(() => {
        setIsRendered(true);
      }, []);

      const lazyloadRef = useBookLazyLoadObserverRef<HTMLDivElement>(onLazyload);
      if (!isRendered) {
        if (useSkeleton) {
          return <BookSkeleton elementRef={lazyloadRef} />;
        }

        return <div className={className} ref={lazyloadRef} />;
      }

      return <BookRenderer index={index}>{children}</BookRenderer>;
    },
  { useSkeleton: false },
);
