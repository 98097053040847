import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const discountInfoStyle = (theme: RIDITheme) => css`
  color: ${theme.colors.red};
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-left: 2px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 11px;
      line-height: 13px;
    `,
  )}
`;

export const originalPriceInfoItemStyle = (theme: RIDITheme) => css`
  color: ${theme.colors.grey500};
`;

export const priceInfoItemStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.blue};
  font-weight: 400;
`;

export const priceItemStyle = (theme: RIDITheme): SerializedStyles => css`
  padding: 0;
  letter-spacing: -0.01em;
  color: ${theme.colors.blue};
`;

export const priceItemMarginStyle = (margin: number): SerializedStyles => css`
  margin-top: ${margin}px;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const priceItemLineHeightStyle = (lineHeight: number): SerializedStyles => css`
  line-height: ${lineHeight}px;
`;

export const priceStyle = css`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
