import { css } from '@emotion/react';
import { useContext } from 'react';

import { IconStarRate } from '@/assets/svgs';
import { formatNumber } from '@/utils/formatNumber';
import {
  ModularComponent,
  modularComponent,
  ModularResponsiveOption,
  modularResponsiveStyle,
} from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';
import * as styles from './BookStarRate.styles';

export const BookStarRateMinimal = modularComponent(
  ({ size }) => {
    const minimalStarRateStyle = [
      styles.minimalStarRateStyle,
      modularResponsiveStyle(styles.minimalStarRateSizeStyle, size),
    ];

    const minimalStarRateIconStyle = [
      styles.minimalStarRateIconStyle,
      modularResponsiveStyle(sizeValue => styles.minimalStarRateIconSizeStyle(sizeValue - 2), size),
    ];

    const minimalStarRateIconNoScoreStyle = [
      styles.minimalStarRateIconNoScoreStyle,
      modularResponsiveStyle(sizeValue => styles.minimalStarRateIconSizeStyle(sizeValue - 2), size),
    ];

    const minimalParticipantsStyle = [
      styles.participantsStyle,
      modularResponsiveStyle(sizeValue => styles.participantsSizeStyle(sizeValue - 1), size),
    ];

    return ({ className }) => {
      const { BookDefaultMetadataItem } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);
      const starRate = bookData.metadata?.starRate;

      if (!starRate) {
        return <></>;
      }

      const { count, score } = starRate;

      if (typeof score !== 'number' || !Number.isFinite(score)) {
        return <></>;
      }
      const showCount = typeof count === 'number' && Number.isFinite(count) && count >= 0;
      const countText = formatNumber(count ?? 0);
      const scoreText = Math.min(5, Math.max(0, score)).toFixed(1);

      const averageLabel = `구매자 평균 별점 ${scoreText}점`;
      const participantsLabel = showCount ? `총 리뷰어 ${countText}명` : '';

      const label = `${participantsLabel}${averageLabel}`;

      return (
        <BookDefaultMetadataItem css={styles.starRateWrapperStyle} className={className} aria-label={label}>
          <span css={minimalStarRateStyle}>
            {score > 0 ? (
              <>
                <IconStarRate css={minimalStarRateIconStyle} />
                {`${scoreText}점 `}
              </>
            ) : (
              <IconStarRate css={minimalStarRateIconNoScoreStyle} />
            )}
          </span>
          {showCount && <span css={minimalParticipantsStyle}>{`(${countText})`}</span>}
        </BookDefaultMetadataItem>
      );
    };
  },
  { size: [{ value: 13 }] as ModularResponsiveOption<number> },
);

export const BookStarRateFull = modularComponent(
  ({ size, showCountUnit, textSize = size }) => {
    const starRateStyle = [styles.starRateStyle, modularResponsiveStyle(styles.starRateSizeStyle, size)];

    const starRateIconBackgroundStyle = [
      styles.starRateIconBackgroundStyle,
      modularResponsiveStyle(styles.starRateIconSizeStyle, size),
    ];

    const starRateIconFillStyle = [
      styles.starRateIconFillStyle,
      modularResponsiveStyle(styles.starRateIconSizeStyle, size),
    ];

    const participantsStyle = [
      styles.participantsStyle,
      modularResponsiveStyle(styles.participantsSizeStyle, textSize),
    ];

    return ({ className }) => {
      const { BookDefaultMetadataItem } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);
      const starRate = bookData.metadata?.starRate;

      if (!starRate) {
        return <></>;
      }

      const { count, score } = starRate;
      if (typeof score !== 'number' || !Number.isFinite(score)) {
        return <></>;
      }

      const starRateWidth = (Math.min(5, Math.max(0, score)) / 5) * 100;

      const showCount = typeof count === 'number' && Number.isFinite(count) && count >= 0;
      const countText = formatNumber(count ?? 0);
      const scoreText = score.toFixed(1);

      const averageLabel = `구매자 평균 별점 ${scoreText}점`;
      const participantsLabel = showCount ? `총 리뷰어 ${countText}명, ` : '';

      const label = `${participantsLabel}${averageLabel}`;

      return (
        <BookDefaultMetadataItem css={styles.starRateWrapperStyle} className={className} aria-label={label}>
          <span css={starRateStyle}>
            <IconStarRate css={starRateIconBackgroundStyle} />
            <IconStarRate
              data-testid="StarRateFill"
              css={[
                starRateIconFillStyle,
                css`
                  width: ${starRateWidth}%;
                `,
              ]}
            />
          </span>

          {showCount && (
            <span css={participantsStyle}>
              {count}
              {showCountUnit && '명'}
            </span>
          )}
        </BookDefaultMetadataItem>
      );
    };
  },
  {
    size: [{ value: 11 }] as ModularResponsiveOption<number>,
    textSize: [{ value: 11 }] as ModularResponsiveOption<number>,
    showCountUnit: true,
  },
);

export const BookStarRate: ModularComponent<{ size: ModularResponsiveOption<number> }> = BookStarRateFull;
