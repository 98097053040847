import { css, SerializedStyles } from '@emotion/react';
import colors from '@ridi/colors';

export const starRateWrapperStyle = css`
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

export const starRateStyle = css`
  position: relative;
  display: inline-block;
`;

export const starRateSizeStyle = (iconSize: number): SerializedStyles => css`
  width: ${iconSize * 5}px;
  height: ${iconSize}px;
`;

export const starRateIconStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
`;

export const starRateIconBackgroundStyle = css`
  ${starRateIconStyle};
  width: 100%;
  color: ${colors.slateGray20};
`;

export const starRateIconSizeStyle = (iconSize: number): SerializedStyles => css`
  height: ${iconSize}px;
`;

export const starRateIconFillStyle = css`
  ${starRateIconStyle};
  color: ${colors.orange40};
`;

export const participantsStyle = css`
  color: ${colors.gray40};
  padding-top: 1px;
  margin: 0 2px;
  line-height: 1em;
`;

export const participantsSizeStyle = (size: number): SerializedStyles => css`
  font-size: ${size}px;
`;

export const minimalStarRateStyle = css`
  font-weight: bold;
  color: ${colors.orange40};
`;

export const minimalStarRateSizeStyle = (size: number): SerializedStyles => css`
  font-size: ${size}px;
`;

export const minimalStarRateIconStyle = css`
  color: ${colors.orange40};
  overflow-x: hidden;
  margin-right: 2px;
`;

export const minimalStarRateIconSizeStyle = (iconSize: number): SerializedStyles => css`
  width: ${iconSize}px;
  height: ${iconSize}px;
`;

export const minimalStarRateIconNoScoreStyle = css`
  ${minimalStarRateIconStyle};
  color: ${colors.slateGray20};
`;

export const minimalParticipantsStyle = participantsStyle;
export const minimalParticipantsSizeStyle = participantsSizeStyle;
