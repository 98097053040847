import { LegacyRef } from 'react';

import { modularComponent, ModularComponentProps } from '@/utils/modularComponent';

export const BookSkeleton = modularComponent(
  () =>
    ({ elementRef, className }: ModularComponentProps & { elementRef?: LegacyRef<HTMLDivElement> }) => (
      <div ref={elementRef} className={className} />
    ),
);
