import { useContext } from 'react';

import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { modularComponent } from '@/utils/modularComponent';

import * as styles from './RenewalBookBadgeRenderer.styles';

export const RenewalBookBadgeRenderer = modularComponent(() => ({ className, children }): ReactJSX.Element => {
  const {
    BookDiscountBadge,
    BookFreebookBadge,
    BookImageBadge,
    BookNewBadge,
    BookRentalBadge,
    BookSelectBadge,
    BookWaitFreeBadge,
  } = useContext(BookDefinitionContext).components;

  return (
    <div css={styles.badgeRendererStyle} className={className}>
      <BookImageBadge />
      <BookNewBadge />
      <BookWaitFreeBadge />
      <BookDiscountBadge />
      <BookSelectBadge />
      <BookFreebookBadge />
      <BookRentalBadge />
      {children}
    </div>
  );
});
