import { useContext } from 'react';

import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { RenewalBookConstantsDefault as constants } from '../constants';
import * as styles from './RenewalBookSetbook.styles';

export const RenewalBookSetbook = modularComponent(
  ({ lineSize, textSize, lineHeight, padding, bottom }) => {
    const setbookWrapperStyle = [
      styles.setbookWrapperStyle,
      modularResponsiveStyle(styles.setbookWrapperPaddingStyle, padding),
      modularResponsiveStyle(styles.setbookWrapperLineSizeStyle, lineSize),
      modularResponsiveStyle(styles.setbookWrapperTextSizeStyle, textSize),
      modularResponsiveStyle(styles.setbookWrapperLineHeightStyle, lineHeight),
      modularResponsiveStyle(styles.setbookWrapperBottomStyle, bottom),
    ];

    return ({ className }): ReactJSX.Element => {
      const bookData = useContext(BookDataContext);

      const setBook = bookData.cover?.setBook;
      const setBookCount = setBook?.count;

      if (typeof setBookCount !== 'number' || !Number.isFinite(setBookCount) || setBookCount <= 0) {
        return <></>;
      }

      return (
        <span css={setbookWrapperStyle} className={className}>
          {setBookCount}권 세트
        </span>
      );
    };
  },
  {
    lineSize: [{ value: constants.BookSetbook.lineSize }] as ModularResponsiveOption<number>,
    textSize: [{ value: constants.BookSetbook.textSize }] as ModularResponsiveOption<number>,
    lineHeight: [{ value: constants.BookSetbook.lineHeight }] as ModularResponsiveOption<number>,
    padding: [{ value: constants.BookSetbook.padding }] as ModularResponsiveOption<string>,
    bottom: [{ value: constants.BookSetbook.bottom }] as ModularResponsiveOption<number>,
  },
);
