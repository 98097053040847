import { css } from '@emotion/react';
import { useContext } from 'react';

import { IconBadgeAdult } from '@/assets/svgs/system';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import * as styles from './BookAdultIndicator.styles';

interface BookAdultIndicatorOptions {
  size: ModularResponsiveOption<number>;
  offset: ModularResponsiveOption<number>;
}

export const BookAdultIndicator = modularComponent(
  ({ size, offset }: BookAdultIndicatorOptions) => {
    const indicatorWrapperStyle = css`
      ${styles.indicatorWrapperBaseStyle};
      ${modularResponsiveStyle(styles.indicatorWrapperSizeStyle, size)};
      ${modularResponsiveStyle(styles.indicatorWrapperOffsetStyle, offset)};
    `;

    return ({ className }): ReactJSX.Element => {
      const isAdultOnly = useContext(BookDataContext).cover?.isAdultOnly ?? false;

      if (!isAdultOnly) {
        return <></>;
      }

      return <IconBadgeAdult aria-label="19세 미만 구독불가" css={indicatorWrapperStyle} className={className} />;
    };
  },
  { size: [{ value: 20 }], offset: [{ value: 3 }] },
);
