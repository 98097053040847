import { css, SerializedStyles } from '@emotion/react';

import { RIDITheme } from '@/components/styles/themes';

export const defaultMetadataItemStyle = (theme: RIDITheme): SerializedStyles => css`
  overflow-wrap: break-word;
  color: ${theme.colors.fillSecondary};
  letter-spacing: -0.01em;
`;

export const defaultMetadataItemTextSizeStyle = (fontSize: number): SerializedStyles => css`
  font-size: ${fontSize}px;
`;

export const defaultMetadataItemLineHeightStyle = (lineHeight: number): SerializedStyles => css`
  line-height: ${lineHeight}px;
`;
