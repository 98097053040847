import { useContext } from 'react';

import { IconBadgeSelect } from '@/assets/svgs/system';
import { BookDataContext } from '@/components/common/Book/BookDataContext';
import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { modularComponent } from '@/utils/modularComponent';

export const RenewalBookSelectBadge = modularComponent(() => ({ className }): ReactJSX.Element => {
  const { BookBadge } = useContext(BookDefinitionContext).components;
  const bookData = useContext(BookDataContext);

  const select = bookData.cover?.badges?.select;
  if (!select) {
    return <></>;
  }

  return (
    <BookBadge background="gold" className={className}>
      <IconBadgeSelect aria-label="셀렉트" />
    </BookBadge>
  );
});
