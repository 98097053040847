import { css } from '@emotion/react';

import { BookAuthorItem, BookAuthors } from '@/components/common/Book/BookMetadataRenderer/BookAuthors';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { RenewalBookConstantsDefault as constants } from '../constants';
import * as styles from './RenewalBookAuthors.styles';

export const RenewalBookAuthors = modularComponent(
  ({ lineHeight, ...options }) => {
    const lineHeightStyle = modularResponsiveStyle(
      value => css`
        line-height: ${value}px;
      `,
      lineHeight,
    );

    const BaseBookAuthor = BookAuthors.withOptions(options);

    return ({ children, ...props }) => (
      <BaseBookAuthor css={lineHeightStyle} {...props}>
        {children}
      </BaseBookAuthor>
    );
  },
  {
    ...BookAuthors.getOptions(),
    textSize: [{ value: constants.BookAuthors.textSize }] as ModularResponsiveOption<number>,
    lineHeight: [{ value: constants.BookAuthors.lineHeight }] as ModularResponsiveOption<number>,
  },
);

export const RenewalBookAuthorItem = BookAuthorItem.addStyle(styles.authorHoverStyle);
