import { useContext } from 'react';

import { modularComponent } from '@/utils/modularComponent';

import { BookDataContext } from '../../BookDataContext';
import { BookDefinitionContext } from '../../BookDefinitionContext';

export const BookBadgeRenderer = modularComponent(() => ({ className }): ReactJSX.Element => {
  const bookData = useContext(BookDataContext);

  const { BookDiscountBadge, BookNewBadge, BookRentalBadge, BookWaitFreeBadge } =
    useContext(BookDefinitionContext).components;

  const badges = bookData?.cover?.badges;

  if (badges?.newRelease) {
    return <BookNewBadge className={className} />;
  }

  if (badges?.waitFree) {
    return <BookWaitFreeBadge className={className} />;
  }

  if (badges?.rental) {
    return <BookRentalBadge className={className} />;
  }

  if (badges?.discount) {
    return <BookDiscountBadge className={className} />;
  }

  return <></>;
});
