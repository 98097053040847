import type { ReactNode } from 'react';

import {
  BookOriginalPriceInfoItem,
  BookPrice,
  BookPriceAdditionalInfoItem,
  BookPriceInfoItem,
  BookPriceItem,
} from '@/components/common/Book/BookMetadataRenderer/BookPrice';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { RenewalBookConstantsDefault as constants } from '../constants';
import * as styles from './RenewalBookPrice.styles';

interface RenewalBookPriceDiscountItemProps {
  percentage: number;
  className?: string;
  children?: ReactNode;
}

export const RenewalBookPriceDiscountItem = modularComponent(
  () =>
    ({ className, percentage, children }: RenewalBookPriceDiscountItemProps) => (
      <span css={styles.discountInfoStyle} className={className}>
        {`(${Math.ceil(percentage)}%)`}
        {children}
      </span>
    ),
);
export const RenewalBookPriceAdditionalInfoItem = BookPriceAdditionalInfoItem;
export const RenewalBookPriceInfoItem = BookPriceInfoItem.addStyle(styles.priceInfoItemStyle);
export const RenewalBookOriginalPriceInfoItem = BookOriginalPriceInfoItem.addStyle(styles.originalPriceInfoItemStyle);
export const RenewalBookPriceItem = modularComponent(
  ({ margin, lineHeight, ...options }) => {
    const priceItemStyle = [
      styles.priceItemStyle,
      modularResponsiveStyle(styles.priceItemMarginStyle, margin),
      modularResponsiveStyle(styles.priceItemLineHeightStyle, lineHeight),
    ];

    return BookPriceItem.withOptions(options).addStyle(priceItemStyle);
  },
  {
    ...BookPriceItem.getOptions(),
    margin: [{ value: constants.BookPriceItem.margin }] as ModularResponsiveOption<number>,
    textSize: [{ value: constants.BookPriceItem.textSize }] as ModularResponsiveOption<number>,
    lineHeight: [{ value: constants.BookPriceItem.lineHeight }] as ModularResponsiveOption<number>,
  },
);

export const RenewalBookPrice = BookPrice.addStyle(styles.priceStyle);
