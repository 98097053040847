import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { RenewalBookConstantsDefault as constants } from '../constants';
import * as styles from './RenewalBookDefaultMetadataItem.styles';

export const RenewalBookDefaultMetadataItem = modularComponent(
  ({ textSize, lineHeight }) => {
    const defaultMetadataItemStyle = [
      styles.defaultMetadataItemStyle,
      modularResponsiveStyle(styles.defaultMetadataItemTextSizeStyle, textSize),
      modularResponsiveStyle(styles.defaultMetadataItemLineHeightStyle, lineHeight),
    ];

    return ({ className, children }) => (
      <p css={defaultMetadataItemStyle} className={className}>
        {children}
      </p>
    );
  },
  {
    textSize: [{ value: constants.BookDefaultMetadataItem.textSize }] as ModularResponsiveOption<number>,
    lineHeight: [{ value: constants.BookDefaultMetadataItem.lineHeight }] as ModularResponsiveOption<number>,
  },
);
