import { ReactNode, useContext, useMemo } from 'react';

import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDefinitionContext } from '../BookDefinitionContext';
import * as styles from './RenewalBookMetadataRenderer.styles';

const defaultCollapsePolicy = [{ value: true }];
export const RenewalBookMetadataItemGroup = modularComponent(
  () =>
    ({
      collapse = defaultCollapsePolicy,
      className,
      children,
    }: {
      collapse?: ModularResponsiveOption<boolean>;
      className?: string;
      children?: ReactNode;
    }) => {
      const itemGroupStyles = useMemo(
        () => [
          styles.metadataItemGroupStyle,
          modularResponsiveStyle(
            value => (value ? styles.metadataItemGroupCollapseStyle : styles.metadataItemGroupDividerStyle),
            collapse,
          ),
        ],
        [collapse],
      );

      return (
        <div css={itemGroupStyles} className={className}>
          {children}
        </div>
      );
    },
);

export const RenewalBookMetadataItems = modularComponent(() => ({ className, children }) => {
  const { BookAuthors, BookStarRate, BookMetadataItemGroup } = useContext(BookDefinitionContext).components;
  return (
    <BookMetadataItemGroup className={className}>
      <BookAuthors css={styles.metadataItemMarginStyle} />
      <BookStarRate css={styles.metadataItemMarginStyle} />
      {children}
    </BookMetadataItemGroup>
  );
});

export const RenewalBookMetadataRenderer = modularComponent(() => ({ className, children, index = 0 }) => {
  const { BookMetadataItems, BookTitle } = useContext(BookDefinitionContext).components;

  return (
    <div css={styles.metadataStyle} className={className}>
      <BookTitle index={index} />
      <BookMetadataItems>{children}</BookMetadataItems>
    </div>
  );
});
