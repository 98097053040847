import { useContext } from 'react';

import { IconBadgeOriginal } from '@/assets/svgs/system';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { RenewalBookConstantsDefault as constants } from '../constants';
import * as styles from './RenewalBookOriginal.styles';

export const RenewalBookOriginal = modularComponent(
  ({ iconSize, iconPosition, gradientHeight }) => {
    const originalIconStyle = [
      styles.originalIconStyle,
      modularResponsiveStyle(styles.originalIconSizeStyle, iconSize),
      modularResponsiveStyle(styles.originalIconPositionStyle, iconPosition),
    ];

    const originalGradientStyle = [
      styles.originalGradientStyle,
      modularResponsiveStyle(styles.originalGradientSizeStyle, gradientHeight),
    ];

    return ({ className }): ReactJSX.Element => {
      const bookData = useContext(BookDataContext);

      const isOriginal = bookData.cover?.original;

      if (!isOriginal) {
        return <></>;
      }

      return (
        <div css={originalGradientStyle} className={className}>
          <IconBadgeOriginal css={originalIconStyle} aria-label="오리지널" />
        </div>
      );
    };
  },
  {
    iconSize: [{ value: constants.BookOriginal.iconSize }] as ModularResponsiveOption<number>,
    iconPosition: [{ value: constants.BookOriginal.iconPosition }] as ModularResponsiveOption<{ x: number; y: number }>,
    gradientHeight: [{ value: constants.BookOriginal.gradientHeight }] as ModularResponsiveOption<number>,
  },
);
