import { css } from '@emotion/react';
import { useContext } from 'react';

import { underlineHoverStyle } from '@/components/styles';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';
import * as styles from './BookTitle.styles';

export const BookTitle = modularComponent(
  ({ textSize, light, hoverEffect }) => {
    const titleTextStyle = [
      styles.titleTextStyle,
      modularResponsiveStyle(styles.titleTextSizeStyle, textSize),
      modularResponsiveStyle(
        lightValue => css`
          ${lightValue && styles.titleLightTextStyle};
        `,
        light,
      ),
    ];

    return ({ className, children, index = 0 }) => {
      const { BookLink, BookTitleBadgeRenderer } = useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);
      const useLink = bookData.link !== null;

      const { title } = bookData;
      if (!title || bookData.metadata?.title?.showTitle === false) {
        return <></>;
      }

      return (
        <div css={styles.titleStyle}>
          <BookTitleBadgeRenderer />
          <BookLink
            css={[...titleTextStyle, hoverEffect && useLink && underlineHoverStyle]}
            className={className}
            index={index}>
            {title}
            {children}
          </BookLink>
        </div>
      );
    };
  },
  {
    textSize: [{ value: 13 }] as ModularResponsiveOption<number>,
    light: [{ value: false }] as ModularResponsiveOption<boolean>,
    hoverEffect: false,
  },
);
