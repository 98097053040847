import styled from '@emotion/styled';

import { BookBadgeProps } from '@/components/common/Book/BookCoverRenderer/BookBadgeRenderer/BookBadge';
import { BookNothing } from '@/components/common/Book/BookNothing';
import { RIDITheme } from '@/components/styles/themes';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { RenewalBookConstantsDefault as constants } from '../../constants';
import * as styles from './RenewalBookBadge.styles';

const BadgeWrapper = styled.div<{ background: string }>`
  ${({ theme }) => styles.badgeBackgroundStyle(theme)};
  background: ${({ background, theme }) => theme.colors[background as keyof RIDITheme['colors']] ?? background};
`;

export const RenewalBookBadge = modularComponent(
  ({ size, contentSize, iconSize }) => {
    const badgeWrapperStyle = [
      modularResponsiveStyle(styles.badgeWrapperSizeStyle, size),
      modularResponsiveStyle(styles.badgeWrapperContentSizeStyle, contentSize),
      modularResponsiveStyle(styles.badgeWrapperIconSizeStyle, iconSize),
    ];

    return ({ ariaLabel, className, children, background }: BookBadgeProps) => (
      <BadgeWrapper aria-label={ariaLabel} className={className} css={badgeWrapperStyle} background={background}>
        {children}
      </BadgeWrapper>
    );
  },
  {
    size: [{ value: constants.BookBadge.size }] as ModularResponsiveOption<number>,
    contentSize: [{ value: constants.BookBadge.contentSize }] as ModularResponsiveOption<number>,
    iconSize: [{ value: constants.BookBadge.iconSize }] as ModularResponsiveOption<number>,
  },
);

export const RenewalBookBadgeText = BookNothing;
