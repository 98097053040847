import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';

export const bookStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const bookTypeStyle = (type: { width: number; height: number }): SerializedStyles => css`
  width: ${type.width}px;
`;

export const coverWrapperStyle = css`
  position: relative;
  align-self: stretch;
`;

export const coverWrapperTypeStyle = (type: { width: number; height: number }): SerializedStyles => css`
  padding-bottom: ${(type.height / type.width) * 100}%;
`;

export const coverStyle = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100%;
`;

export const metadataMarginStyle = (margin: number): SerializedStyles => css`
  margin-top: ${margin}px;
`;

export const metadataPaddingRightStyle = (paddingRight: number): SerializedStyles => css`
  padding-right: ${paddingRight}px;
`;

export const touchAreaStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      pointer-events: auto;
    `,
  )};
`;
