import { ReactNode, useContext, useMemo } from 'react';

import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDefinitionContext } from '../BookDefinitionContext';
import * as styles from './BookMetadataRenderer.styles';

export const BookMetadataRenderer = modularComponent(() => ({ children, index = 0 }) => {
  const { BookMetadataItems, BookTitle } = useContext(BookDefinitionContext).components;

  return (
    <>
      <BookTitle index={index} />
      <BookMetadataItems>{children}</BookMetadataItems>
    </>
  );
});

export const BookMetadataItems = modularComponent(() => ({ children }) => {
  const { BookAuthors, BookPrice, BookStarRate, BookSeries } = useContext(BookDefinitionContext).components;
  return (
    <>
      <BookAuthors />
      <BookStarRate />
      <BookSeries />
      <BookPrice />
      {children}
    </>
  );
});

const defaultCollapsePolicy = [{ value: false }];
export const BookMetadataItemGroup = modularComponent(
  () =>
    ({
      collapse = defaultCollapsePolicy,
      className,
      children,
    }: {
      collapse?: ModularResponsiveOption<boolean>;
      className?: string;
      children?: ReactNode;
    }) => {
      const itemGroupStyles = useMemo(
        () => [
          styles.metadataItemGroupStyle,
          modularResponsiveStyle(
            value => (value ? styles.metadataItemGroupCollapseStyle : styles.metadataItemGroupDividerStyle),
            collapse,
          ),
        ],
        [collapse],
      );

      return (
        <div css={itemGroupStyles} className={className}>
          {children}
        </div>
      );
    },
);
