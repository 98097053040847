import { css, SerializedStyles } from '@emotion/react';
import colors from '@ridi/colors';

export const defaultMetadataItemStyle = css`
  font-weight: normal;
  line-height: 1.2em;
  overflow-wrap: break-word;
  color: ${colors.gray60};
`;

export const defaultMetadataItemSizeStyle = (fontSize: number): SerializedStyles => css`
  font-size: ${fontSize}px;
`;
