import { createContext } from 'react';

import {
  createIntersectionObserverProvider,
  createUseIntersectionObserverRef,
  IntersectionObserverContextType,
} from '@/utils/intersectionObserverProvider';

const BookLazyLoadObserverContext = createContext<IntersectionObserverContextType>(null);
export const BookLazyLoadObserverProvider = createIntersectionObserverProvider(BookLazyLoadObserverContext, {
  rootMargin: '75px',
});
export const useBookLazyLoadObserverRef = createUseIntersectionObserverRef(BookLazyLoadObserverContext);
