import { useContext } from 'react';

import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import * as styles from './BookSetbook.styles';

export const BookSetbook = modularComponent(
  ({ textSize, borderSize, padding }) => {
    const setbookTextWrapperStyle = [
      styles.setbookTextWrapperStyle,
      modularResponsiveStyle(styles.setbookTextWrapperBorderSizeStyle, borderSize),
    ];

    const setbookTextStyle = [
      styles.setbookTextStyle,
      modularResponsiveStyle(styles.setbookTextSizeStyle, textSize),
      modularResponsiveStyle(styles.setbookTextPaddingStyle, padding),
    ];

    return ({ className }): ReactJSX.Element => {
      const bookData = useContext(BookDataContext);

      const setBook = bookData.cover?.setBook;
      const setBookCount = setBook?.count;

      if (typeof setBookCount !== 'number' || !Number.isFinite(setBookCount) || setBookCount <= 0) {
        return <></>;
      }

      return (
        <span css={styles.setbookWrapperStyle} className={className}>
          <span css={setbookTextWrapperStyle}>
            <span css={setbookTextStyle}>{setBookCount}권 세트</span>
            <span css={styles.setbookBorderStyle} />
          </span>
        </span>
      );
    };
  },
  {
    textSize: [{ value: 12 }] as ModularResponsiveOption<number>,
    borderSize: [{ value: 2 }] as ModularResponsiveOption<number>,
    padding: [{ value: '5px 7px' }] as ModularResponsiveOption<string>,
  },
);
