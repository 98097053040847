import { css } from '@emotion/react';

export const badgeRendererStyle = css`
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;

  display: flex;
`;
