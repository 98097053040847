import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

const renewalInfoAutorsBaseStyle = css`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const renewal2InfoAuthorsStyle = css`
  ${renewalInfoAutorsBaseStyle};
  margin-top: 6px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 4px;
    `,
  )};
`;

export const renewal2InfoMetadataStyle = css`
  min-height: 61px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      min-height: 51px;
    `,
  )};
`;

export const renewal2InfoSkeletonStyle = css`
  width: 180px;
  height: 332px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 130px;
      height: 232px;
    `,
  )};
`;

export const renewal3InfoAuthorsStyle = css`
  ${renewalInfoAutorsBaseStyle};
  margin-top: 6px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 4px;
    `,
  )}
`;

export const renewal3InfoStarRateStyle = css`
  margin-top: 7px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 5px;
    `,
  )}
`;

export const renewal3InfoMetadataStyle = css`
  min-height: 84px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      min-height: 69px;
    `,
  )};
`;

export const renewal3InfoSkeletonStyle = css`
  width: 180px;
  height: 355px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 130px;
      height: 251px;
    `,
  )};
`;

export const renewal5InfoAuthorsStyle = css`
  ${renewalInfoAutorsBaseStyle};
  margin-top: 6px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 4px;
    `,
  )};
`;

export const renewal5InfoSeriesStyle = css`
  /* BookSeriesCompletion이 1px 높이 올라간 건 박스에 반영되지 않음 */
  margin-top: 2px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 2px;
      font-size: 13px;
      line-height: 16px;
    `,
  )};
`;

export const renewal5InfoStarRateStyle = css`
  margin-top: 7px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 4px;
    `,
  )};
`;

export const renewal5InfoPriceStyle = css`
  margin-top: 6px;
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 4px;
    `,
  )};
`;

const MOBILE_SERIES_HEIGHT = 16;
const MOBILE_PRICE_HEIGHT = 32;
const DESKTOP_SERIES_HEIGHT = 19;
const DESKTOP_PRICE_HEIGHT = 41;
export const renewal5InfoMetadataStyle = (showSeries: boolean, showPrice: boolean): SerializedStyles => css`
  min-height: ${84 + (showSeries ? DESKTOP_SERIES_HEIGHT : 0) + (showPrice ? DESKTOP_PRICE_HEIGHT : 0)}px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      min-height: ${69 + (showSeries ? MOBILE_SERIES_HEIGHT : 0) + (showPrice ? MOBILE_PRICE_HEIGHT : 0)}px;
    `,
  )};
`;

export const renewal5InfoSkeletonStyle = (showSeries: boolean, showPrice: boolean): SerializedStyles => css`
  width: 180px;
  height: ${355 + (showSeries ? DESKTOP_SERIES_HEIGHT : 0) + (showPrice ? DESKTOP_PRICE_HEIGHT : 0)}px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 110px;
      height: ${249 + (showSeries ? MOBILE_SERIES_HEIGHT : 0) + (showPrice ? MOBILE_PRICE_HEIGHT : 0)}px;
    `,
  )};
`;

export const renewalThumbnailSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.grey100};
  width: 180px;
  height: 261px;
  margin-bottom: 10px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 130px;
      height: 188px;
      margin-bottom: 6px;
    `,
  )}
`;

export const renewalTitleSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.grey100};
  width: 154px;
  height: 20px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 100px;
      height: 18px;
    `,
  )}
`;

export const renewalAuthorSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.grey100};
  width: 124px;
  height: 16px;
  margin-top: 6px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 72px;
      height: 16px;
      margin-top: 4px;
    `,
  )}
`;

export const renewalStarRateSkeletonStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.grey100};
  width: 50px;
  height: 16px;
  margin-top: 7px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 36px;
      height: 14px;
      margin-top: 4px;
    `,
  )}
`;
