import { ComponentProps, ComponentType, createContext } from 'react';

import { EventParamsType } from '@/utils/eventClient';
import { ModularComponent } from '@/utils/modularComponent';
import type { RDTTrackingURIParams } from '@/utils/query';

import type * as components from './components';

type BookComponentRecords = typeof components;
export type BookComponents = {
  [K in keyof BookComponentRecords]: ComponentType<ComponentProps<BookComponentRecords[K]>>;
};

export type BookTrackingData = {
  screenName: string;
  params?: EventParamsType;
  rdtTrackingURIParams?: Omit<RDTTrackingURIParams, 'sectionItemIdx'>;
};

type ModularBookComponentKeys = {
  [K in keyof BookComponentRecords]: BookComponentRecords[K] extends ModularComponent<Any, Any> ? K : never;
}[keyof BookComponents];

export type ModularBookComponents = {
  [K in ModularBookComponentKeys]: BookComponentRecords[K];
};

export type BookDefinitionContextType = {
  components: BookComponents;
  trackingData?: BookTrackingData;
};

export const BookDefinitionContext = createContext<BookDefinitionContextType>({} as BookDefinitionContextType);
