import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const starRateWrapperStyle = (hasScore: boolean, theme: RIDITheme): SerializedStyles => css`
  display: flex;
  color: ${hasScore ? theme.colors.red : theme.colors.fillSecondary};
`;

export const starRateWrapperTextSizeStyle = (textSize: number): SerializedStyles => css`
  font-size: ${textSize}px;
`;

export const starRateWrapperLineHeightStyle = (lineHeight: number): SerializedStyles => css`
  line-height: ${lineHeight}px;
`;

export const starRateIconStyle = (hasScore: boolean, theme: RIDITheme): SerializedStyles => css`
  margin: 3px 2px 2px 0;
  color: ${hasScore ? theme.colors.red : theme.colors.grey400};

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin: 1px 1px 2px 0;
    `,
  )}
`;

export const starRateIconSizeStyle = (size: number): SerializedStyles => css`
  font-size: ${size}px;
`;

export const starRateCountStyle = (theme: RIDITheme): SerializedStyles => css`
  margin-left: 1px;
  color: ${theme.colors.fillSecondary};
`;
