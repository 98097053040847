import { forwardRef, memo, useContext } from 'react';

import {
  modularComponent,
  ModularComponentProps,
  ModularResponsiveOption,
  modularResponsiveStyle,
} from '@/utils/modularComponent';

import { BookDefinitionContext } from '../BookDefinitionContext';
import * as styles from './BookRenderer.styles';

export const BookRenderer = modularComponent(
  ({ size, fixedHeight }) => {
    const portraitBookStyle = modularResponsiveStyle(styles.bookStyle, size);
    const portraitCoverStyle = modularResponsiveStyle(styles.coverStyle(fixedHeight), size);

    return memo(
      forwardRef<HTMLDivElement, ModularComponentProps>(({ className, children, index }, ref) => {
        const { BookCoverRenderer, BookMetadataRenderer, BookViewTracker } =
          useContext(BookDefinitionContext).components;

        return (
          <BookViewTracker>
            <div css={portraitBookStyle} className={className} ref={ref}>
              <div css={portraitCoverStyle}>
                <BookCoverRenderer index={index} />
              </div>
              <div css={styles.metadataStyle}>
                <BookMetadataRenderer index={index} />
                {children}
              </div>
            </div>
          </BookViewTracker>
        );
      }),
    );
  },
  { size: [{ value: 80 }] as ModularResponsiveOption<number>, fixedHeight: true },
);
