import { useContext, useMemo } from 'react';

import { BookDataContext } from '../BookDataContext';
import { BookThumbnailData, DEFAULT_THUMBNAIL_PARAMS, getImageSrcSet, getImageUrlForWidth } from '../utils/thumbnail';

export const useThumbnailSrc = (
  defaultWidth: number,
  isImageLazyLoaded: boolean,
): { src?: string; srcSet?: string } => {
  const bookData = useContext(BookDataContext);
  const thumbnailData = bookData.cover?.thumbnail;

  const { bookId } = bookData;
  const [thumbnailId, thumbnails] = useMemo(() => {
    if (thumbnailData) {
      if ('thumbnailId' in thumbnailData) {
        return [thumbnailData.thumbnailId, {} as BookThumbnailData];
      }

      return [undefined, thumbnailData as BookThumbnailData];
    }

    return [bookId, {} as BookThumbnailData];
  }, [thumbnailData, bookId]);

  const [imageURL, imageSrcSet] = useMemo(() => {
    if (!isImageLazyLoaded) {
      return [undefined, undefined];
    }

    let availableThumbnails = DEFAULT_THUMBNAIL_PARAMS.filter(param => typeof thumbnails?.[param.type] === 'string');
    if (availableThumbnails.length === 0) {
      availableThumbnails = DEFAULT_THUMBNAIL_PARAMS;
    }

    const src = getImageUrlForWidth(defaultWidth, availableThumbnails, thumbnailId, thumbnails);
    if (!src) {
      return [undefined, undefined];
    }

    const srcSet = getImageSrcSet(availableThumbnails, thumbnailId, thumbnails);

    return [src, srcSet];
  }, [defaultWidth, isImageLazyLoaded, thumbnailId, thumbnails]);

  return {
    src: imageURL,
    srcSet: imageSrcSet,
  };
};
