import { css } from '@emotion/react';
import colors from '@ridi/colors';

import { lineClamp } from '@/components/styles';

export const descriptionWrapperStyle = css`
  display: flex;
  margin: 6px 0;
`;

export const descriptionStyle = css`
  color: ${colors.gray60};
  overflow-wrap: break-word;
  font-size: 13px;
  ${lineClamp(3, '1.5em')};
`;
